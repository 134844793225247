import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { departmentPermissions } from "../../../../utilities/permissions";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Flex,
  Text,
  Alert,
  AlertIcon,
  Switch,
  Select,
  Box,
  Collapse,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import theme from "../../../global/theme";
import {
  updateAdminDepartment,
  updateUser,
} from "../../../../store/users/usersSlice";
import { getDepartments } from "../../../../store/departments/departmentsSlice";

const UpdateDepartment = ({ data = null, onClose }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const departments = useSelector((state) => state.departments);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartments({}));
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      permissions: data?.permissions || [],
      department: data?.department || "",
      _id: data?._id,
    },
  });

  watch("permissions", "department");
  useEffect(() => {
    setValue("permissions", data?.permissions || []);
    setValue("department", data?.department || "");
    setValue("_id", data?._id || "");
  }, [data, departments]);

  const handleCheckAll = () => {
    const allPermissions = departmentPermissions.flatMap((permissionGroup) =>
      permissionGroup.permissions.map((perm) => perm.value)
    );
    // setValue("permissions", allPermissions);
    setValue("permissions", allPermissions, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleUncheckAll = () => {
    setValue("permissions", []);
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent
        borderRadius={8}
        paddingBlock={4}
        bg={theme.dark}
        boxShadow="lg"
      >
        <form
          onSubmit={handleSubmit((values) => {
            console.log(values);
            dispatch(updateAdminDepartment(values))
              .unwrap()
              .then((_) => {
                onClose();
              });
          })}
        >
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize="lg"
          >
            القسم
          </ModalHeader>
          <ModalBody>
            {departments.errors.length > 0 && (
              <Alert
                status="error"
                variant="left-accent"
                marginBottom={8}
                borderRadius={4}
              >
                <AlertIcon />
                {departments.errors.map((error, index) => (
                  <Text key={index}>{error?.msg}</Text>
                ))}
              </Alert>
            )}

            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                اختر القسم
              </FormLabel>
              <Select
                bg={theme.bg}
                color={theme.dark}
                border="none"
                borderRadius={4}
                placeholder={"اختر القسم"}
                {...register("department")}
              >
                {departments?.data?.data?.map((dept) => (
                  <option key={dept._id} value={dept._id}>
                    {dept.name}
                  </option>
                ))}
              </Select>
              {errors.department?.message && (
                <Text color="red.600" marginTop={2}>
                  {errors.department.message}
                </Text>
              )}
            </FormControl>

            <Box mt={8} border="1px solid #444" borderRadius="md" p={4}>
              <Flex
                justify="space-between"
                align="center"
                cursor="pointer"
                onClick={() => setIsOpen(!isOpen)}
              >
                <Text fontWeight="bold" color="white">
                  صلاحيات إضافية للموظف
                </Text>
                <Button
                  size="sm"
                  bg={theme.success}
                  color={theme.light}
                  _hover={{ bg: theme.success }}
                >
                  {isOpen ? "إخفاء" : "عرض"}
                </Button>
              </Flex>

              <Collapse in={isOpen} animateOpacity>
                <Flex mt={4} justifyContent="space-between">
                  <Button
                    bg={theme.success}
                    color={theme.light}
                    _hover={{ bg: theme.success }}
                    onClick={handleCheckAll}
                  >
                    {t("pages.rules.check_all")}
                  </Button>

                  <Button
                    bg="red.600"
                    color={theme.light}
                    _hover={{ bg: "red.700" }}
                    onClick={handleUncheckAll}
                  >
                    عدم تحديد الكل
                  </Button>
                </Flex>

                <Flex gap={4} flexWrap="wrap" flexDirection="row" mt={4}>
                  {departmentPermissions.map((permissionGroup) => (
                    <FormControl
                      key={permissionGroup.name}
                      pb={2}
                      pr={2}
                      borderBottom="1px solid #444"
                      borderRight="1px solid #444"
                      w="fit-content"
                    >
                      <FormLabel
                        fontWeight="semibold"
                        textTransform="capitalize"
                        color="white"
                      >
                        {permissionGroup.name}
                      </FormLabel>
                      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={4}>
                        {permissionGroup.permissions.map((perm) => (
                          <Flex key={perm.value} align="center" gap={4}>
                            <Switch
                              id={perm.value}
                              value={perm.value}
                              isChecked={watch("permissions")?.includes(
                                perm.value
                              )}
                              colorScheme="teal"
                              onChange={(e) => {
                                const currentPermissions =
                                  watch("permissions") || [];
                                setValue(
                                  "permissions",
                                  e.target.checked
                                    ? [...currentPermissions, perm.value]
                                    : currentPermissions.filter(
                                        (p) => p !== perm.value
                                      ),
                                  { shouldValidate: true, shouldDirty: true }
                                );
                              }}
                            />
                            <FormLabel
                              htmlFor={perm.value}
                              ml={2}
                              mb={0}
                              color={theme.light}
                              textTransform="capitalize"
                            >
                              {perm.name}
                            </FormLabel>
                          </Flex>
                        ))}
                      </SimpleGrid>
                    </FormControl>
                  ))}
                </Flex>
              </Collapse>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end" gap={4} w="full">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color={theme.light}
                bg="green.500"
                _hover={{ bg: "green.600" }}
                borderRadius={8}
              >
                {t("general.save")}
              </Button>
              <Button
                type="button"
                rightIcon={<FiIcons.FiMinimize2 />}
                color={theme.light}
                bg="red.500"
                _hover={{ bg: "red.600" }}
                ml={4}
                borderRadius={8}
                onClick={onClose}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UpdateDepartment;
