export const permissions = [
  {
    page_name: "dashboard",
    page_access: ["manage"],
  },
  {
    page_name: "settings",
    page_access: ["manage"],
  },
  {
    page_name: "users",
    page_access: ["manage"],
  },
  {
    page_name: "warehouses",
    page_access: ["manage"],
  },
  {
    page_name: "products",
    page_access: ["manage"],
  },
  {
    page_name: "accounts",
    page_access: ["manage"],
  },
  {
    page_name: "requests",
    page_access: ["manage"],
  },
  {
    page_name: "tickets",
    page_access: ["manage"],
  },
];
export const departmentPermissions = [
  {
    name: "لوحة التحكم",
    key: "dashboard",
    permissions: [
      {
        name: "شهري",
        value: "monthly",
      },
      {
        name: "كلي",
        value: "total",
      },
    ],
  },
  {
    name: "الإعدادات",
    key: "settings",
    permissions: [
      {
        name: "إدارة",
        value: "manage_settings",
      },
    ],
  },
  {
    name: "المنتجات",
    key: "products",
    permissions: [
      {
        name: "(إضافة وتعديل)إدارة المنتجات",
        value: "manage_products",
      },
      {
        name: "(إضافة وتعديل)الاستوك الوهمي ",
        value: "fake_stock",
      },
      {
        name: "(إضافة وتعديل)الاستوك الفعلي ",
        value: "real_stock",
      },
      {
        name: "إدارة أقسام المنتجات",
        value: "manage_categories",
      },
      {
        name: "ترتيب المنتجات",
        value: "sort_products",
      },
      {
        name: "المنتجات االأقل نشاطاً",
        value: "inactive_products",
      },
      {
        name: "المنتجات الناقصة ستوك ",
        value: "low_products",
      },
    ],
  },

  {
    name: "الاوردرات",
    key: "orders",
    permissions: [
      {
        name: "(تغيير حالة الاوردر والملاحظات)إدارة",
        value: "manage_orders",
      },
      {
        name: "توزيع الاوردرات",
        value: "distribute_orders",
      },
      {
        name: "الطلبات الشهرية",
        value: "monthly_orders",
      },
      {
        name: "كل الطلبات",
        value: "total_orders",
      },
      {
        name: "إرجاع حالة الاوردرت",
        value: "reverse_orders",
      },
      {
        name: "نسخ الاوردرات",
        value: "clone_order",
      },
      {
        name: "تأكيد وشحن الاوردرات",
        value: "confirm_orders",
      },
      {
        name: "تغيير شركة الشحن",
        value: "change_shipping_company",
      },
    ],
  },
  {
    name: "المستخدمين",
    key: "users",
    permissions: [
      {
        name: "إدارة المستخدمين",
        value: "manage_users",
      },
      {
        name: "إدارة أكونتات المسوقين والتجار",
        value: "manage_accounts",
      },
    ],
  },
  {
    name: "الإحصائيات",
    key: "statistics",
    permissions: [
      {
        name: "المسوقين",
        value: "marketers_statistics",
      },
      {
        name: "معدلات السحب",
        value: "rates_statistics",
      },
      {
        name: "المخزن",
        value: "stock_statistics",
      },
      {
        name: "شركات الشحن",
        value: "shipping_statistics",
      },
      {
        name: "أنشطة الأدمن",
        value: "admin_statistics",
      },
      {
        name: "إحصائيات الأدمن",
        value: "adminStatics_statistics",
      },
    ],
  },
  {
    name: "المخازن",
    key: "warehouses",
    permissions: [
      {
        name: "إدارة المخازن",
        value: "manage_warehouses",
      },
    ],
  },
  {
    name: "شركات الشحن",
    key: "shipping_companies",
    permissions: [
      {
        name: "إدارة شركات الشحن",
        value: "manage_shipping_companies",
      },
    ],
  },
  {
    name: "التنبيهات",
    key: "alerts",
    permissions: [
      {
        name: "إدارة التنبيهات",
        value: "manage_alerts",
      },
    ],
  },
  {
    name: "المقترحات",
    key: "suggestions",
    permissions: [
      {
        name: "إدارة المقترحات",
        value: "manage_suggestions",
      },
    ],
  },
  {
    name: "الأرقام المحظورة",
    key: "blocked_numbers",
    permissions: [
      {
        name: "إدارة الأرقام المحظورة",
        value: "manage_blocked_numbers",
      },
    ],
  },
  {
    name: "طلبات السحب",
    key: "requests",
    permissions: [
      {
        name: "إدارة",
        value: "manage_requests",
      },
    ],
  },
];
