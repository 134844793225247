import { Box, Image, Link as ChakraLink, Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { NavLink, Outlet } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";
import theme from "../../global/theme";

import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";

import { ProfileWrapper } from "./ProfileStyle";
import noImage from "../../../assets/images/no_img.jpg";

const Profiles = () => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  return (
    <ProfileWrapper>
      <Breadcrumbs
        currentPage={t("pages.users.profile")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      <Flex
        p={6}
        bg={theme.light}
        boxShadow={theme.shadow}
        borderBottom={`1px solid ${theme.border}`}
        alignItems="center"
        justifyContent="center"
      >
        <Box textAlign="center">
          <Image
            src={auth.user.image ? `/users/${auth.user.image}` : noImage}
            w="120px"
            h="120px"
            borderRadius="50%"
            m="auto"
          />
          <Box color={theme.text} textTransform="capitalize">
            {auth.user.name}
          </Box>
          <Box color={theme.dark} textTransform="capitalize">
            {auth.user.role}
          </Box>
        </Box>
      </Flex>

      <Flex bg={theme.light} p={4} mb={8}>
        <ChakraLink
          as={NavLink}
          me={4}
          to="/profile/account"
          color={theme.dark}
          _focus={{ outline: "none" }}
        >
          حسابي
        </ChakraLink>
        <ChakraLink
          as={NavLink}
          me={4}
          to="/profile/notifications"
          color={theme.dark}
          _focus={{ outline: "none" }}
        >
          التحكم في إشعارات المتصفح
        </ChakraLink>
        <ChakraLink
          as={NavLink}
          me={4}
          to="/profile/update"
          color={theme.dark}
          _focus={{ outline: "none" }}
        >
          تحديث كلمة المرور
        </ChakraLink>
      </Flex>

      <Outlet />
    </ProfileWrapper>
  );
};

export default Profiles;
