import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Flex,
  Button,
  Text,
  SimpleGrid,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";

import InputSearch from "../../shared/inputSearch/InputSearch";
import Pagination from "../../shared/pagination/Pagination";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import DepartmentsTable from "./DepartmentsTable";
import Filter from "./actions/Filter";

import { getDepartments } from "../../../store/departments/departmentsSlice";
import theme from "../../global/theme";
import DepartmentModal from "./DepartmentModal";

const Departments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const departments = useSelector((state) => state.departments);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState(JSON.stringify({}));

  const [showCreateRule, setShowCreateRule] = useState(false);
  const [showUpdateRule, setShowUpdateRule] = useState(false);
  const [showDeleteRule, setShowDeleteRule] = useState(false);

  useEffect(() => {
    dispatch(getDepartments({ page, query, filter }));
  }, [dispatch, page, query, filter]);

  return (
    <>
      <Breadcrumbs
        currentPage={"الأقسام"}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {departments.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {departments.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      <SimpleGrid columns={{ sm: 1, md: 2 }} justifyContent="space-between">
        <Flex marginBottom={8}>
          <Button
            bg="red.600"
            textTransform="uppercase"
            fontWeight="bold"
            fontSize={18}
            marginInlineEnd={4}
            color="white"
            _hover={{ bg: "red.600" }}
            onClick={() => setShowCreateRule(true)}
          >
            {t("general.create")}
          </Button>
        </Flex>

        <Flex marginBottom={8} justifyContent="flex-end">
          <InputSearch
            isLoading={departments.isLoading}
            handleSearch={(query) => setQuery(query)}
          />

          <Filter
            handleFilter={(values) => setFilter(values)}
            handleClearFilter={() => setFilter(JSON.stringify({}))}
            disablesBtns={departments.isLoading}
          />
        </Flex>
      </SimpleGrid>

      {departments.data?.itemsCount > 0 ? (
        <DepartmentsTable
          data={departments.data.data}
          page={page}
          handleUpdate={(el) => setShowUpdateRule(el)}
          handleDelete={(el) => setShowDeleteRule(el)}
        />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={4}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            الأقسام غير متوفرة
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={departments.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

      {showCreateRule && (
        <DepartmentModal
          onClose={() => {
            setShowCreateRule(false);
            dispatch(getDepartments({ page, query, filter }));
          }}
        />
      )}
      {showUpdateRule && (
        <DepartmentModal
          data={showUpdateRule}
          onClose={() => {
            setShowUpdateRule(false);
            dispatch(getDepartments({ page, query, filter }));
          }}
        />
      )}
    </>
  );
};

export default Departments;
