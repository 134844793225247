import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import { departmentPermissions } from "../utilities/permissions";
export const getPermissionsByKey = (key) => {
  const department = departmentPermissions.find((dept) => dept.key === key);
  return department ? department.permissions.map((perm) => perm.value) : [];
};

const useAuth = () => {
  const auth = useSelector((state) => state.auth);
  const permissions = auth.user?.department?.permissions ?? [];
  const extraPermissions = auth.user?.permissions ?? [];

  const combinedPermissions = useMemo(() => {
    return [...permissions, ...extraPermissions];
  }, [permissions, extraPermissions]);

  const checkRole = useCallback(
    (...roles) => roles.includes(auth.user?.role),
    [auth?.user]
  );
  const checkDepartment = useCallback(
    (department) => auth.user?.department?.name === department,
    [auth?.user]
  );

  const checkPermissions = useCallback(
    (...specificPermissions) =>
      combinedPermissions.some((permission) =>
        specificPermissions.includes(permission)
      ) && auth.user?.role === "admin",
    [combinedPermissions, auth?.user]
  );

  return { checkRole, checkPermissions, checkDepartment };
};

export default useAuth;
