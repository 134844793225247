import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { departmentPermissions } from "../../../utilities/permissions";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Flex,
  Text,
  Alert,
  AlertIcon,
  Switch,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import {
  createDepartment,
  updateDepartment,
} from "../../../store/departments/departmentsSlice";
import theme from "../../global/theme";

const DepartmentModal = ({ data = null, onClose }) => {
  const { t } = useTranslation();
  const departments = useSelector((state) => state.departments);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: data || { permissions: [] },
  });

  watch("permissions");

  const onSubmit = (values) => {
    const action = data ? updateDepartment : createDepartment;
    dispatch(action(values))
      .unwrap()
      .then(() => onClose());
  };

  const handleCheckAll = () => {
    const allPermissions = departmentPermissions.flatMap((permissionGroup) =>
      permissionGroup.permissions.map((perm) => perm.value)
    );
    setValue("permissions", allPermissions, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  const handleUncheckAll = () => {
    setValue("permissions", []);
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent
        borderRadius={8}
        paddingBlock={4}
        bg={theme.dark}
        boxShadow="lg"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize="lg"
          >
            {data ? t("general.update") : t("general.create")} قسم
          </ModalHeader>
          <ModalBody>
            {departments.errors.length > 0 && (
              <Alert
                status="error"
                variant="left-accent"
                marginBottom={8}
                borderRadius={4}
              >
                <AlertIcon />
                {departments.errors.map((error, index) => (
                  <Text key={index}>{error?.msg}</Text>
                ))}
              </Alert>
            )}

            <FormControl>
              <FormLabel
                color={theme.light}
                fontWeight="semibold"
                textTransform="capitalize"
              >
                {t("pages.rules.name")}
              </FormLabel>
              <Input
                type="text"
                bg={theme.light}
                border="none"
                borderRadius={8}
                placeholder={t("pages.rules.name")}
                _placeholder={{ textTransform: "capitalize" }}
                {...register("name", {
                  required: t("validation.required"),
                })}
              />
              {errors.name?.message && (
                <Text color="red.500" marginTop={2}>
                  {errors.name.message}
                </Text>
              )}
            </FormControl>

            <Flex mt={8} justifyContent="space-between">
              <Button
                bg={theme.success}
                color={theme.light}
                _hover={{ bg: theme.success }}
                onClick={handleCheckAll}
              >
                {t("pages.rules.check_all")}
              </Button>

              <Button
                bg="red.600"
                color={theme.light}
                _hover={{ bg: "red.700" }}
                onClick={handleUncheckAll}
              >
                عدم تحديد الكل
              </Button>
            </Flex>

            <Flex gap={4} flexWrap="wrap" flexDirection={"row"}>
              {departmentPermissions.map((permissionGroup) => (
                <FormControl
                  key={permissionGroup.name}
                  mt={4}
                  pb={2}
                  pr={2}
                  borderBottom="1px solid #444"
                  borderRight="1px solid #444"
                  w="fit-content"
                >
                  <FormLabel
                    fontWeight="semibold"
                    textTransform="capitalize"
                    color="white"
                  >
                    {permissionGroup.name}
                  </FormLabel>
                  <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={4}>
                    {permissionGroup.permissions.map((perm) => (
                      <Flex key={perm.value} align="center" gap={4}>
                        <Switch
                          id={perm.value}
                          value={perm.value}
                          isChecked={watch("permissions")?.includes(perm.value)}
                          colorScheme="teal"
                          onChange={(e) => {
                            const currentPermissions =
                              watch("permissions") || [];
                            if (e.target.checked) {
                              // Add permission if checked
                              setValue(
                                "permissions",
                                [...currentPermissions, perm.value],
                                { shouldValidate: true, shouldDirty: true }
                              );
                            } else {
                              // Remove permission if unchecked
                              setValue(
                                "permissions",
                                currentPermissions.filter(
                                  (p) => p !== perm.value
                                ),
                                { shouldValidate: true, shouldDirty: true }
                              );
                            }
                          }}
                        />

                        <FormLabel
                          htmlFor={perm.value}
                          ml={2}
                          mb={0}
                          color={theme.light}
                          textTransform="capitalize"
                        >
                          {perm.name}
                        </FormLabel>
                      </Flex>
                    ))}
                  </SimpleGrid>
                </FormControl>
              ))}
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end" gap={4} w="full">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color={theme.light}
                bg="green.500"
                _hover={{ bg: "green.600" }}
                borderRadius={8}
              >
                {t("general.save")}
              </Button>
              <Button
                type="button"
                rightIcon={<FiIcons.FiMinimize2 />}
                color={theme.light}
                bg="red.500"
                _hover={{ bg: "red.600" }}
                ml={4}
                borderRadius={8}
                onClick={onClose}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default DepartmentModal;
