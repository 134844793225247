import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import * as FiIcons from "react-icons/fi";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Flex,
  SimpleGrid,
  Box,
  Text,
  FormControl,
  FormLabel,
  CheckboxGroup,
  Checkbox,
  Input,
  Select,
} from "@chakra-ui/react";

import Search from "../../../shared/search/Search";

import { getShippingCompanies } from "../../../../store/shippingCompanies/shippingCompaniesSlice";
import {
  updateAssignedOrders,
  updateOrderShippingCompany,
} from "../../../../store/orders/ordersSlice";

import theme from "../../../global/theme";
import Pagination from "../../../shared/pagination/Pagination";
import { getUsers } from "../../../../store/users/usersSlice";
import { getDepartments } from "../../../../store/departments/departmentsSlice";

const UpdateAssignModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const orders = useSelector((state) => state.orders);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const departments = useSelector((state) => state.departments);

  useEffect(() => {
    dispatch(getDepartments({}));
  }, [dispatch]);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");

  const { setValue, handleSubmit, getValues, watch, control } = useForm({
    defaultValues: {
      orders: data,
      assigned_to: [],
    },
  });

  watch(["assigned_to"]);
  const {
    fields: assignedTo,
    append: appendAccess,
    remove: removeAccess,
  } = useFieldArray({
    name: "assigned_to",
    control,
  });

  useEffect(() => {
    let filterByDepartment = {};
    if (selectedDepartment) {
      filterByDepartment = { department: selectedDepartment };
    }
    dispatch(
      getUsers({
        page,
        query,
        role: "admin",
        filter: JSON.stringify({
          is_active: true,
          role: "admin",
          ...filterByDepartment,
        }),
      })
    );
  }, [dispatch, page, query, selectedDepartment]);

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={20} padding={4} bg={theme.dark}>
        <form
          onSubmit={handleSubmit((values) => {
            const finalValues = values;
            finalValues.assigned_to = values.assigned_to.map((el) => {
              return el._id;
            });

            dispatch(updateAssignedOrders(finalValues))
              .unwrap()
              .then((_) => {
                document.querySelectorAll("button").forEach((el) => {
                  el.remove();
                });
                alert("تم توزيع الطلبات بنجاح");
                window.location.reload();
              })
              .catch((_) => {
                onClose();
              });
          })}
        >
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            توزيع الطلبات
          </ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                اختر القسم
              </FormLabel>
              <Select
                bg={theme.bg}
                color={theme.dark}
                value={selectedDepartment}
                border="none"
                borderRadius={4}
                placeholder={"اختر القسم"}
                onChange={(e) => setSelectedDepartment(e.target.value)}
              >
                {departments?.data?.data?.map((dept) => (
                  <option key={dept._id} value={dept._id}>
                    {dept.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <Box>
              <FormControl>
                <FormLabel color="white">البحث عن مستخدم</FormLabel>
                <Input
                  type="text"
                  bg={theme.bg}
                  color={theme.dark}
                  border="none"
                  borderRadius={4}
                  placeholder="اكتب للبحث..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </FormControl>

              <FormControl mt={6}>
                <Box marginInline={5}>
                  <FormLabel fontWeight="bold" color="white" mb={4}>
                    اختر الأشخاص
                  </FormLabel>

                  <CheckboxGroup
                  // value={getValues("assign_to").map((user) => user._id)}
                  >
                    <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={2}>
                      {users?.data?.data?.map((user) => (
                        <Checkbox
                          key={user._id}
                          value={user._id}
                          colorScheme="orange"
                          color="white"
                          isChecked={assignedTo.some(
                            (el) => el._id === user._id
                          )}
                          onChange={() => {
                            const exists = assignedTo.find(
                              (el) => el._id === user._id
                            );
                            if (exists) {
                              removeAccess(
                                assignedTo.findIndex(
                                  (el) => el._id === user._id
                                )
                              );
                            } else {
                              appendAccess(user);
                            }
                          }}
                        >
                          {user.name || user.email}
                        </Checkbox>
                      ))}
                    </SimpleGrid>
                  </CheckboxGroup>
                </Box>
              </FormControl>

              <Flex justifyContent="flex-end" mt={4}>
                <Pagination
                  page={page}
                  itemsCount={users.data?.itemsCount ?? 0}
                  onChange={(page) => setPage(page)}
                />
              </Flex>

              <Text color="white" mt={4}>
                {`تم تحديد ${getValues("assigned_to").length} مستخدم`}
              </Text>
              {assignedTo.length === 0 ? (
                <Box mt={4} textAlign="center" color={theme.light}>
                  لا يوجد أدمنز
                </Box>
              ) : (
                <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={2} mt={4}>
                  {assignedTo.map((el, index) => (
                    <Flex marginBlock={4} alignItems="center" key={el.id}>
                      <Box w="calc(80% - 50px)" color={theme.light}>
                        {el.name}
                      </Box>
                      <Button
                        type="button"
                        bg={theme.error}
                        color={theme.light}
                        onClick={() => removeAccess(index)}
                        ms={3}
                      >
                        <FiIcons.FiDelete />
                      </Button>
                    </Flex>
                  ))}
                </SimpleGrid>
              )}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white"
                bg="green"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                isLoading={orders.isLoading}
                _hover={{ background: "green" }}
              >
                {t("general.save")}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white"
                bg="red.600"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                marginInlineStart={4}
                isLoading={orders.isLoading}
                _hover={{ background: "red.600" }}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UpdateAssignModal;
