import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Switch,
  FormControl,
  FormLabel,
  Box,
  Flex,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { FaBell } from "react-icons/fa";
import { updateMe } from "../../../../store/users/usersSlice";
import { checkAuthentication } from "../../../../store/auth/authSlice";

const NotificationsTab = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const user = auth.user || {};

  const toast = useToast({
    position: "top",
    duration: 5000,
    status: "success",
  });

  const handleToggle = (field) => {
    dispatch(
      updateMe({
        _id: user._id,
        ...{ [field]: !user[field] },
      })
    ).then(() => {
      toast({
        title: "تم تحديث الإعدادات بنجاح",
        status: "success",
      });
      dispatch(checkAuthentication()).unwrap();
    });
  };

  const notificationFields = {
    marketer: [
      { label: "إشعارات طلبات السحب", field: "notify_withdraw_requests" },
      { label: "إشعارات ملاحظات الطلب", field: "notify_order_notes" },
      { label: "التنبيهات العامة", field: "notify_general_alerts" },
    ],
    admin: [
      { label: "إشعارات ملاحظات الطلب", field: "notify_order_notes" },
      { label: "إشعارات طلباتي", field: "notify_my_orders" },
    ],
    moderator: [
      { label: "إشعارات ملاحظات الطلب", field: "notify_order_notes" },
    ],
  };

  const userFields = notificationFields[user.role] || [];

  return (
    <Box borderRadius={12} p={6} bg="white">
      <Heading size="md" marginBottom={6} display="flex" alignItems="center">
        <FaBell style={{ marginRight: 8 }} /> إعدادات الإشعارات
      </Heading>
      <Flex direction="column" gap={6}>
        {userFields.map(({ label, field }) => (
          <FormControl
            key={field}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <FormLabel fontWeight="bold">{label}</FormLabel>
            <Switch
              colorScheme="teal"
              size="lg"
              isChecked={user[field]}
              onChange={() => handleToggle(field)}
            />
          </FormControl>
        ))}
      </Flex>
    </Box>
  );
};

export default NotificationsTab;
