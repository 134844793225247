import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Box,
  Text,
  Select,
  SimpleGrid,
  Flex,
  useToast,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import Search from "../../../shared/search/Search";
import { getProducts } from "../../../../store/products/productsSlice";
import { updateStockRule } from "./../../../../store/stock/stockSlice";
import theme from "../../../global/theme";

const UpdateStockModal = ({ onClose }) => {
  const { t } = useTranslation();
  const products = useSelector((state) => state.products);
  const rules = useSelector((state) => state.stock);

  const dispatch = useDispatch();
  const toast = useToast({ duration: 2000, position: "top", status: "error" });

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      items: [...rules.data, { property: null, decrease_by: 0, each: "12h" }],
    },
  });

  const {
    fields: items,
    append: appendItem,
    remove: removeItem,
  } = useFieldArray({
    name: "items",
    control,
  });

  watch(["items"]);

  const [productsPage, setProductsPage] = useState(1);
  const [productsQuery, setProductsQuery] = useState("");
  const productsDefaultFilter = { is_active: true };
  const [productsFilter] = useState(JSON.stringify(productsDefaultFilter));

  useEffect(() => {
    dispatch(
      getProducts({
        page: productsPage,
        query: productsQuery,
        filter: productsFilter,
      })
    );
  }, [dispatch, productsPage, productsQuery, productsFilter]);

  const onSubmit = async (values) => {
    const items = values.items;

    // global validation
    if (items.length === 0) {
      return toast({ description: "من فضلك اختر على الاقل منتج واحد!" });
    }

    try {
      await dispatch(updateStockRule(items)).unwrap();
      onClose();
    } catch (error) {
      window.location.reload();
    }
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            تناقص المخزون
          </ModalHeader>
          <ModalBody>
            {items.map((item, index) => (
              <SimpleGrid
                key={item.id}
                columns={{ sm: 1, md: 2, lg: 5 }}
                border={`1px solid ${theme.border}`}
                alignItems="center"
                paddingBlock={2}
                mb={2}
                borderRadius={4}
              >
                <Box marginInline={5}>
                  <Box color={theme.light} marginBlock={2}>
                    المنتج
                  </Box>
                  <Search
                    page={productsPage}
                    itemsCount={products.data?.itemsCount}
                    data={products.data?.data}
                    prop="name"
                    currentElement={getValues(`items.${index}.product`)}
                    handleChangePage={(page) => setProductsPage(page)}
                    handleChangeQuery={(query) => setProductsQuery(query)}
                    handleSelected={(selectedProduct) => {
                      setValue(`items.${index}.product`, selectedProduct);
                      setValue(`items.${index}.property`, null); // Reset property when product changes
                    }}
                  />
                </Box>
                <Box marginInline={5}>
                  <Box color={theme.light} marginBlock={2}>
                    الخاصية
                  </Box>
                  <Select
                    minW="100px"
                    color={theme.light}
                    _placeholder={{ color: theme.dark }}
                    {...register(`items.${index}.property`, {
                      required: `${t("general.required")}`,
                    })}
                  >
                    {getValues(`items.${index}.product.properties`)?.map(
                      (prop, idx) => (
                        <option
                          key={idx}
                          style={{ color: theme.dark }}
                          value={prop._id}
                        >
                          {prop.key} - {prop.value} قطع
                        </option>
                      )
                    )}
                  </Select>
                </Box>

                {/* <Box marginInline={5}>
                  <Box color={theme.light} marginBlock={2}>
                    الكمية
                  </Box>
                  <Input
                    type="number"
                    color={theme.light}
                    step="0.01"
                    min="0"
                    defaultValue={getValues(`items.${index}.decrease_by`)}
                    placeholder="تنقيص بمقدار"
                    {...register(`items.${index}.decrease_by`, {
                      required: `${t("general.required")}`,
                      min: { value: 0, message: `${t("أقل رقم")} 0` },
                    })}
                  />
                  {errors.items?.[index]?.decrease_by && (
                    <Text color="red.600" marginTop={2}>
                      {errors.items[index].decrease_by.message}
                    </Text>
                  )}
                </Box> */}

                <Box marginInline={5}>
                  <Box color={theme.light} marginBlock={2}>
                    تنقيص كل فترة
                  </Box>
                  <Select
                    color={theme.light}
                    {...register(`items.${index}.each`, {
                      required: `${t("general.required")}`,
                    })}
                  >
                    <option value="3h" style={{ color: theme.dark }}>
                      كل 3 ساعات
                    </option>
                    <option value="6h" style={{ color: theme.dark }}>
                      كل 6 ساعات
                    </option>
                    <option value="9h" style={{ color: theme.dark }}>
                      كل 9 ساعة
                    </option>
                    <option value="12h" style={{ color: theme.dark }}>
                      كل 12 ساعة
                    </option>
                  </Select>
                </Box>

                <Box marginInline={5} mt={2}>
                  {index === 0 ? (
                    <Button
                      bg={theme.success}
                      color={theme.light}
                      size="xs"
                      onClick={() =>
                        appendItem({
                          property: null,
                          decrease_by: 0,
                          each: "12h",
                        })
                      }
                    >
                      إضافة منتج أخر
                    </Button>
                  ) : (
                    <Button
                      bg="red"
                      color="white"
                      size="xs"
                      onClick={() => removeItem(index)}
                    >
                      حذف المنتج
                    </Button>
                  )}
                </Box>
              </SimpleGrid>
            ))}
          </ModalBody>

          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white"
                bg="green"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                disabled={rules?.isLoading}
                _hover={{ background: "green" }}
              >
                {t("general.save")}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white"
                bg="red.600"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                marginInlineStart={4}
                _hover={{ background: "red.600" }}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UpdateStockModal;
