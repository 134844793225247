import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Collapse,
  Badge,
  Flex,
  Heading,
  useToast,
} from "@chakra-ui/react";
import { FiArrowUp, FiArrowDown, FiChevronRight } from "react-icons/fi";
import { getPropertyLogs } from "../../../../store/products/productsSlice";
import Pagination from "../../../shared/pagination/Pagination";
import { useParams } from "react-router-dom";

const PropertyLogsView = ({ propertyId }) => {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const toast = useToast();
  const { propertyLogs, loading } = useSelector((state) => state.products);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [expandedDate, setExpandedDate] = useState(null);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        await dispatch(
          getPropertyLogs({
            propertyId,
            productId,
            page,
            limit: size,
          })
        );
      } catch (error) {
        toast({
          title: "Error loading logs",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    if (propertyId) {
      fetchLogs();
    }
  }, [propertyId, page, size, dispatch]);

  const handleDailyChange = (first, last) => {
    const diff = last - first;
    return {
      value: Math.abs(diff),
      direction: diff > 0 ? "up" : diff < 0 ? "down" : "neutral",
      color: diff > 0 ? "green.500" : "red.500",
    };
  };

  return (
    <Box p={8} maxW="1400px" mx="auto" overflowX={"auto"}>
      <Table variant="striped" colorScheme="gray" size="md">
        <Thead bg="teal.500">
          <Tr>
            <Th color="white">التاريخ</Th>
            <Th color="white">القيمة الأولية</Th>
            <Th color="white">القيمة النهائية</Th>
            <Th color="white">التغيير اليومي</Th>
            <Th color="white">عدد التعديلات</Th>
            <Th color="white">التفاصيل</Th>
          </Tr>
        </Thead>
        <Tbody>
          {propertyLogs?.data?.map((dailyLog) => {
            const change = handleDailyChange(
              dailyLog.firstValue,
              dailyLog.lastValue
            );

            return (
              <React.Fragment key={dailyLog.date}>
                <Tr
                  onClick={() =>
                    setExpandedDate(
                      expandedDate === dailyLog.date ? null : dailyLog.date
                    )
                  }
                  cursor="pointer"
                  _hover={{ bg: "gray.50" }}
                >
                  <Td fontWeight="600">
                    {new Date(dailyLog.date).toLocaleString()}
                  </Td>
                  <Td>{dailyLog.firstValue}</Td>
                  <Td>{dailyLog.lastValue}</Td>
                  <Td>
                    <Flex align="center" gap={2}>
                      {change.direction === "up" && <FiArrowUp color="green" />}
                      {change.direction === "down" && (
                        <FiArrowDown color="red" />
                      )}
                      <Text color={change.color}>{change.value}</Text>
                    </Flex>
                  </Td>
                  <Td>{dailyLog.changesCount}</Td>
                  <Td>
                    <FiChevronRight
                      transform={
                        expandedDate === dailyLog.date ? "rotate(90deg)" : ""
                      }
                      transition="transform 0.2s"
                    />
                  </Td>
                </Tr>

                <Tr>
                  <Td colSpan={6} p={0}>
                    <Collapse in={expandedDate === dailyLog.date}>
                      <Box bg="gray.50" p={4}>
                        <Heading size="sm" mb={4}>
                          تفاصيل التغييرات ليوم
                          {new Date(dailyLog.date).toLocaleString()}
                        </Heading>

                        <Table variant="simple" size="sm">
                          <Thead>
                            <Tr>
                              <Th>الوقت</Th>
                              <Th>القيمة السابقة</Th>
                              <Th>القيمة الجديدة</Th>
                              <Th>الفرق</Th>
                              <Th>السبب</Th>
                              {/* <Th>المستخدم</Th> */}
                            </Tr>
                          </Thead>
                          <Tbody>
                            {dailyLog.changes.map((change, index) => (
                              <Tr key={index}>
                                <Td>
                                  {/* {moment(change.timestamp).format("HH:mm")} */}
                                  {new Date(change.timestamp).toLocaleString()}
                                </Td>
                                <Td>{change.oldValue}</Td>
                                <Td>{change.newValue}</Td>
                                <Td>
                                  <Badge
                                    colorScheme={
                                      change.newValue > change.oldValue
                                        ? "green"
                                        : "red"
                                    }
                                  >
                                    {change.newValue - change.oldValue}
                                  </Badge>
                                </Td>
                                <Td>{change.reason || "بدون سبب"}</Td>
                                {/* <Td>{change.changedBy?.name || "مجهول"}</Td> */}
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Box>
                    </Collapse>
                  </Td>
                </Tr>
              </React.Fragment>
            );
          })}
        </Tbody>
      </Table>

      {propertyLogs?.data?.length === 0 && (
        <Box textAlign="center" py={8}>
          <Text fontSize="xl">لا توجد سجلات متاحة لهذه الخاصية</Text>
        </Box>
      )}

      <Flex justify="center" mt={8}>
        <Pagination
          page={page}
          itemsCount={propertyLogs?.metadata?.totalDays || 1}
          onChange={(page) => setPage(page)}
        />
      </Flex>
    </Box>
  );
};

export default PropertyLogsView;
