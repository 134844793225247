import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getStatisticsMarketers,
  getProductRates,
  getProductStock,
  getActivitiesCountByUser,
  clearActivities,
  getAdminStatistics,
  getShippingCompanyStatistics,
} from "../../../store/reports/reportsSlice";
import {
  Flex,
  Box,
  Heading,
  Select,
  useBreakpointValue,
  SimpleGrid,
  Breadcrumb,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import DataTable, {
  ActivityTable,
  AdminDataTable,
  MarketersDataTable,
  ShippingTable,
  StockTable,
} from "./DataTable";
import Filter from "./Filter";
import { IoStorefront } from "react-icons/io5";
import ActivitiesTabs from "./ActivitiesList";
import Pagination from "../../shared/pagination/Pagination";
import { FaUser, FaDollarSign, FaCogs, FaShippingFast } from "react-icons/fa";
import InputSearch from "../../shared/inputSearch/InputSearch";
import useAuth from "./../../../hooks/useAuth";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ChartComponent = () => {
  const [additionalParams, setAdditionalParams] = useState({
    filterType: "day",
  });
  const { checkPermissions } = useAuth();
  const columns = useBreakpointValue({ base: 2, lg: 4 });
  const dispatch = useDispatch();
  const [view, setView] = useState("");
  const [page, setPage] = useState(1);
  const reports = useSelector((state) => state.reports);
  const auth = useSelector((state) => state.auth);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (view === "admin") {
      dispatch(clearActivities());
    }

    setPage(1);
  }, [view]);
  const availableTabs = [
    checkPermissions("marketers_statistics") && "marketers",
    checkPermissions("rates_statistics") && "rates",
    checkPermissions("stock_statistics") && "stock",
    checkPermissions("shipping_statistics") && "shipping",
    auth.user?.rule?.name === "superadmin" &&
      checkPermissions("admin_statistics") &&
      "admin",
    auth.user?.rule?.name === "superadmin" &&
      checkPermissions("adminStatics_statistics") &&
      "adminStatics",
  ].filter(Boolean);

  useEffect(() => {
    setView(availableTabs[0]);
  }, []);

  return (
    <Flex direction="column" w="100%">
      <Breadcrumbs
        currentPage={"الإحصائيات"}
        pages={[{ name: "لوحة التحكم", path: "/" }]}
      />
      <Tabs
        variant="enclosed"
        isLazydefaultIndex={0}
        onChange={(index) => {
          // Update the view based on the tab index
          // Remove falsy values
          setView(availableTabs[index]);
        }}
      >
        <TabList
          mb="4"
          gridTemplateColumns={`repeat(${columns}, 1fr)`}
          display="grid"
          gap={{ base: 3, lg: 4 }}
        >
          {checkPermissions("marketers_statistics") && (
            <Tab p={{ base: 1, lg: 2 }}>
              <FaUser style={{ margin: "0px 15px" }} />
              المسوقون
            </Tab>
          )}
          {checkPermissions("rates_statistics") && (
            <Tab p={{ base: 1, lg: 2 }}>
              <FaDollarSign style={{ margin: "0px 15px" }} />
              معدلات السحب
            </Tab>
          )}
          {checkPermissions("stock_statistics") && (
            <Tab p={{ base: 1, lg: 2 }}>
              <IoStorefront style={{ margin: "0px 15px" }} />
              المخزن
            </Tab>
          )}
          {checkPermissions("shipping_statistics") && (
            <Tab p={{ base: 1, lg: 2 }}>
              <FaShippingFast style={{ margin: "0px 15px" }} />
              شركات الشحن
            </Tab>
          )}
          {auth.user?.rule?.name === "superadmin" && (
            <>
              {checkPermissions("admin_statistics") && (
                <Tab p={{ base: 1, lg: 2 }}>
                  <FaCogs style={{ margin: "0px 15px" }} />
                  أنشطة الأدمن
                </Tab>
              )}
              {checkPermissions("adminStatics_statistics") && (
                <Tab p={{ base: 1, lg: 2 }}>
                  <FaCogs style={{ margin: "0px 15px" }} />
                  إحصائيات الأدمن
                </Tab>
              )}
            </>
          )}
        </TabList>
        <TabPanels>
          {checkPermissions("marketers_statistics") && (
            <TabPanel>
              {view === "marketers" && (
                <>
                  <Filter
                    handleFilter={(values) => {
                      setAdditionalParams({ ...additionalParams, ...values });
                      dispatch(
                        getStatisticsMarketers({
                          filter: { ...additionalParams, ...values },
                        })
                      );
                    }}
                    handleClearFilter={() => {
                      setAdditionalParams({ filterType: "day" });
                    }}
                    disablesBtns={reports.isLoading}
                  />
                  <Heading size="md" mb={6} mt={6} p={4} bg={"#efeefe"}>
                    إحصائيات المسوقون
                  </Heading>
                  <MarketersDataTable
                    data={reports?.data?.marketers?.mostPopularMarketers || []}
                    diviedBy={1}
                  />
                  <Flex justifyContent="flex-end">
                    <Pagination
                      page={page}
                      size={20}
                      itemsCount={reports?.data?.marketers?.totalPage ?? 0}
                      onChange={(page) => {
                        setPage(page);
                        dispatch(
                          getStatisticsMarketers({
                            filter: { ...additionalParams },
                            page,
                          })
                        );
                      }}
                    />
                  </Flex>
                </>
              )}
            </TabPanel>
          )}
          {checkPermissions("rates_statistics") && (
            <TabPanel>
              {view === "rates" && (
                <>
                  <Filter
                    view={"rates"}
                    handleFilter={(values) => {
                      setAdditionalParams({ ...additionalParams, ...values });
                      dispatch(
                        getProductRates({
                          filter: { ...additionalParams, ...values },
                        })
                      );
                    }}
                    handleClearFilter={() => {
                      setAdditionalParams({ filterType: "day" });
                    }}
                    disablesBtns={reports.isLoading}
                  />
                  <Heading size="md" mb={6} mt={6} p={4} bg={"#efeefe"}>
                    معدلات السحب للمنتجات
                  </Heading>
                  <DataTable
                    status={additionalParams?.status || "pending"}
                    reports={reports?.data?.rates || []}
                    data={reports?.data?.rates?.mostPopularProducts || []}
                    diviedBy={1}
                  />
                  <Flex justifyContent="flex-end">
                    <Pagination
                      page={page}
                      size={20}
                      itemsCount={reports?.data?.rates?.totalPage ?? 0}
                      onChange={(page) => {
                        setPage(page);

                        dispatch(
                          getProductRates({
                            filter: { ...additionalParams },
                            page,
                          })
                        );
                      }}
                    />
                  </Flex>
                </>
              )}
            </TabPanel>
          )}
          {checkPermissions("stock_statistics") && (
            <TabPanel>
              {view === "stock" && (
                <>
                  <Filter
                    view={"stock"}
                    handleFilter={(values) => {
                      setAdditionalParams({ ...additionalParams, ...values });
                      dispatch(
                        getProductStock({
                          filter: { ...additionalParams, ...values },
                        })
                      );
                    }}
                    handleClearFilter={() => {
                      setAdditionalParams({ filterType: "day" });
                    }}
                    disablesBtns={reports.isLoading}
                  />

                  <Heading size="md" mb={6} mt={6} p={4} bg={"#efeefe"}>
                    متابعة المخزون
                  </Heading>
                  <StockTable
                    reports={reports?.data?.stock || []}
                    data={reports?.data?.stock?.mostPopularProducts || []}
                    diviedBy={1}
                  />
                  <Flex justifyContent="flex-end">
                    <Pagination
                      page={page}
                      size={20}
                      itemsCount={reports?.data?.stock?.totalPage ?? 0}
                      onChange={(page) => {
                        setPage(page);

                        dispatch(
                          getProductStock({
                            filter: { ...additionalParams },
                            page,
                          })
                        );
                      }}
                    />
                  </Flex>
                </>
              )}
            </TabPanel>
          )}
          {checkPermissions("shipping_statistics") && (
            <TabPanel>
              {view === "shipping" && (
                <>
                  <Filter
                    view={"shipping"}
                    handleFilter={(values) => {
                      setAdditionalParams({ ...additionalParams, ...values });
                      dispatch(
                        getShippingCompanyStatistics({
                          filter: { ...additionalParams, ...values },
                        })
                      );
                    }}
                    handleClearFilter={() => {
                      setAdditionalParams({ filterType: "day" });
                    }}
                    disablesBtns={reports.isLoading}
                  />

                  <Heading size="md" mb={6} mt={6} p={4} bg={"#efeefe"}>
                    متابعة شركات الشحن
                  </Heading>
                  <ShippingTable
                    reports={reports?.data?.shipping || []}
                    data={reports?.data?.shipping?.statistics || []}
                    diviedBy={1}
                  />
                </>
              )}
            </TabPanel>
          )}

          {checkPermissions("admin_statistics") && (
            <TabPanel>
              {view === "admin" && (
                <>
                  <Filter
                    view="admin"
                    handleFilter={(values) => {
                      setAdditionalParams({ ...additionalParams, ...values });
                      dispatch(
                        getActivitiesCountByUser({
                          filter: { ...additionalParams, ...values },
                        })
                      );
                    }}
                    handleClearFilter={() => {
                      setAdditionalParams({ filterType: "day" });
                    }}
                    disablesBtns={reports.isLoading}
                  />
                  {/* {additionalParams?.admin && ( */}
                  <>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                      {reports.data?.activities && (
                        <>
                          {reports?.data?.activities.map((item) => (
                            <ActivityTable data={item} />
                          ))}
                        </>
                      )}
                    </SimpleGrid>
                    {reports.data?.activitiesList?.length > 0 && (
                      <>
                        <ActivitiesTabs
                          activities={reports?.data?.activitiesList}
                        />
                      </>
                    )}
                    <Flex justifyContent="flex-end">
                      <Pagination
                        page={page}
                        size={10}
                        itemsCount={reports.data?.activities?.itemsCount ?? 0}
                        onChange={(page) => {
                          setPage(page);

                          dispatch(
                            getActivitiesCountByUser({
                              filter: { ...additionalParams },
                              page,
                            })
                          );
                        }}
                      />
                    </Flex>
                  </>
                </>
              )}
            </TabPanel>
          )}
          {checkPermissions("adminStatics_statistics") && (
            <TabPanel>
              {view === "adminStatics" && (
                <>
                  <Filter
                    view="adminStatics"
                    handleFilter={(values) => {
                      setAdditionalParams({ ...additionalParams, ...values });
                      dispatch(
                        getAdminStatistics({
                          filter: { ...additionalParams, ...values },
                        })
                      );
                    }}
                    handleClearFilter={() => {
                      setAdditionalParams({ filterType: "day" });
                    }}
                    disablesBtns={reports.isLoading}
                  />
                  {/* {additionalParams?.admin && ( */}
                  <>
                    <Heading size="md" mb={6} mt={6} p={4} bg={"#efeefe"}>
                      إحصائيات الأدمن
                    </Heading>
                    <AdminDataTable
                      data={reports?.data?.adminStatistics || []}
                    />
                  </>
                </>
              )}
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default ChartComponent;
