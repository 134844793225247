import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Flex,
  Button,
  Text,
  SimpleGrid,
  Alert,
  AlertIcon,
  Spinner,
  FormControl,
  FormLabel,
  Switch,
  useDisclosure,
  ModalFooter,
  Input,
  Box,
  Heading,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  Modal,
} from "@chakra-ui/react";

import Pagination from "../../shared/pagination/Pagination";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import ProductsTable from "./ProductsTable";
import MinProductsTable from "./MinProductsTable";
import CreateModal from "./actions/CreateModal";
import UpdateModal from "./actions/UpdateModal";
import UpdateAccessModal from "./actions/UpdateAccessModal";
import Filter from "./actions/Filter";

import {
  getMinProducts,
  getProducts,
  updateProduct,
  updateProductQuantities,
} from "../../../store/products/productsSlice";

import { ProductsWrapper } from "./ProductsStyle";

import theme from "../../global/theme";
import InputSearch from "../../shared/inputSearch/InputSearch";
import UpdateStockModal from "./actions/UpdateStockModal";
import { useSearchParams } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import { FiDelete, FiPlus, FiSave, FiX } from "react-icons/fi";
import EditProductStock from "./actions/EditProductStock";

const MinProducts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const auth = useSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get("page") || 1;
  const query = searchParams.get("query") || "";
  const [selectedProduct, setSelectedProduct] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleEditClick = async (product, e) => {
    e.stopPropagation();

    try {
      const response = await dispatch(
        getProducts({ filter: JSON.stringify({ _id: product._id }) })
      ).unwrap();

      if (response?.data?.length) {
        setSelectedProduct(response.data[0]);
        onOpen();
      }
    } catch (error) {
      console.error("Failed to fetch product:", error);
    }
  };

  const onSubmit = (values) => {
    dispatch(
      updateProductQuantities({
        id: selectedProduct._id,
        values: {
          properties: JSON.stringify(values.properties),
        },
      })
    ).then(() => {
      onClose();
      dispatch(getMinProducts({ page, query, filter: JSON.stringify(filter) }));
    });
  };

  // State for filter and switches
  const [filter, setFilter] = useState({
    is_active: true,
    is_archived: false,
  });

  useEffect(() => {
    dispatch(getMinProducts({ page, query, filter: JSON.stringify(filter) }));
  }, [dispatch, page, query, filter]);

  // Handle switch toggle

  return (
    <ProductsWrapper>
      <Breadcrumbs
        currentPage={"منتجات ناقصة ستوك"}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />
      {products.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {products.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}
      {/* products.isLoading ? (
      <Flex padding={6} alignItems="center" justifyContent="center">
        <Spinner />
      </Flex>
      ) : */}
      {products.min?.itemsCount > 0 ? (
        <MinProductsTable
          data={products.min.data}
          page={page}
          handleEditClick={handleEditClick}
        />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={16}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            {t("pages.products.no_products")}
          </Text>
        </Flex>
      )}
      {products.min?.itemsCount > 0 && !products.isLoading && (
        <Flex justifyContent="flex-end">
          <Pagination
            page={page}
            itemsCount={products.min?.itemsCount ?? 0}
            onChange={(page) => {
              searchParams.set("page", page);
              setSearchParams(searchParams);
            }}
          />
        </Flex>
      )}
      <EditProductStock
        isOpen={isOpen}
        onClose={onClose}
        selectedProduct={selectedProduct}
        onSubmit={onSubmit}
      />
      {/*<Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader textAlign="center" color="orange" fontSize={22}>
              تعديل المنتج
            </ModalHeader>
            <ModalBody>
      
              <SimpleGrid
                columns={{ base: 2, md: 3, lg: 4 }}
                border={`1px solid ${theme.border}`}
                gridTemplateColumns={{
                  base: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                  lg: "1fr repeat(3, 150px)",
                }}
                alignItems="center"
                paddingBlock={2}
                mb={2}
                borderRadius={4}
                gap={2}
              >
                {properties.map((el, index) => (
                  <React.Fragment key={el.id}>
                    <Box marginInline={5} me={2} mb={3}>
                      <Input
                        bg="white"
                        type="text"
                        minW="170px"
                        {...register(`properties.${index}.key`)}
                        placeholder="خاصية المنتج"
                      />
                    </Box>

                    <Box marginInline={5} mb={3}>
                      <Input
                        bg="white"
                        type="number"
                        minW="30px"
                        {...register(`properties.${index}.value`)}
                        placeholder="القيمة"
                      />
                    </Box>

                    <Box marginInline={5} mb={3}>
                      <Input
                        bg="white"
                        type="number"
                        minW="30px"
                        {...register(`properties.${index}.min`)}
                        placeholder="الحد الأدنى"
                      />
                    </Box>

                    <Flex spacing={4} mb={3}>
                      <Button
                        type="button"
                        bg={theme.success}
                        color={theme.light}
                        onClick={() => append({})}
                        me={2}
                      >
                        <FiPlus />
                      </Button>
                      <Button
                        type="button"
                        bg={theme.error}
                        color={theme.light}
                        onClick={() => remove(index)}
                      >
                        <FiDelete />
                      </Button>
                    </Flex>
                  </React.Fragment>
                ))}
              </SimpleGrid>

              <Heading size="md" mb={4} color={theme.light}>
                إدارة المخزون
              </Heading>
              <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={6} mb={4}>
                {properties.map((el, index) => (
                  <Flex
                    key={el.id}
                    direction="column"
                    gap={2}
                    border={`1px solid ${theme.border}`}
                    p={4}
                    borderRadius={8}
                    bg={theme.background}
                  >
                    <Box>
                      <Text color={theme.light} fontSize="sm">
                        الخاصية:
                      </Text>
                      <Input
                        bg="white"
                        value={getValues(`properties.${index}.key`)}
                        readOnly
                      />
                    </Box>

                    <Box>
                      <Text color={theme.light} fontSize="sm">
                        الكمية الفعلية:
                      </Text>
                      <Input
                        bg="white"
                        value={getValues(`properties.${index}.real_stock`)}
                        readOnly
                      />
                    </Box>

                    <Box>
                      <Text color={theme.light} fontSize="sm">
                        التعديل:
                      </Text>
                      <Input
                        bg="white"
                        type="number"
                        {...register(`properties.${index}.adjustmentValue`)}
                        placeholder="+/- الكمية"
                      />
                    </Box>

                    <Button
                      type="button"
                      bg={theme.primary}
                      color={theme.light}
                      onClick={() => handleAdjustment(index)}
                    >
                      تأكيد التعديل
                    </Button>
                  </Flex>
                ))}
              </SimpleGrid>
            </ModalBody>

            <ModalFooter>
              <Flex justifyContent="flex-end" gap={4}>
                <Button
                  type="submit"
                  colorScheme="green"
                  rightIcon={<FiSave />}
                >
                  {t("general.save")}
                </Button>
                <Button onClick={onClose} colorScheme="red" rightIcon={<FiX />}>
                  {t("general.close")}
                </Button>
              </Flex>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>*/}
    </ProductsWrapper>
  );
};

export default MinProducts;
