import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import theme from "../../global/theme";

import {
  Alert,
  AlertIcon,
  Text,
  SimpleGrid,
  Flex,
  Button,
} from "@chakra-ui/react";

import InputSearch from "../../shared/inputSearch/InputSearch";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import Pagination from "../../shared/pagination/Pagination";
import ShippingCompaniesTable from "./ShippingCompaniesTable";
import CreateModal from "./actions/CreateModal";
import UpdateModal from "./actions/UpdateModal";
import DeleteModal from "./actions/DeleteModal";
import Filter from "./actions/Filter";

import { getShippingCompanies } from "../../../store/shippingCompanies/shippingCompaniesSlice";

import { ShippingCompaniesWrapper } from "./ShippingCompaniesStyle";

const ShippngCompanies = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const shippingCompanies = useSelector((state) => state.shippingCompanies);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState(JSON.stringify({}));

  const [showCreateShippingCompany, setShowCreateShippingCompany] =
    useState(false);
  const [showUpdateShippingCompany, setShowUpdateShippingCompany] =
    useState(false);
  const [showDeleteShippingCompany, setShowDeleteShippingCompany] =
    useState(false);

  useEffect(() => {
    dispatch(getShippingCompanies({ page, query, filter }));
  }, [dispatch, page, query, filter]);

  return (
    <ShippingCompaniesWrapper>
      <Breadcrumbs
        currentPage={t("pages.shipping_companies.shipping_companies")}
        pages={[{ name: t("pages.dashboard.dashboard"), path: "/" }]}
      />

      {shippingCompanies.errors?.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {shippingCompanies.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      <SimpleGrid columns={{ sm: 1, md: 2 }} justifyContent="space-between">
        <Flex marginBottom={8}>
          <Button
            bg="red.600"
            textTransform="uppercase"
            fontWeight="bold"
            fontSize={18}
            marginInlineEnd={4}
            color="white"
            _hover={{ bg: "red.600" }}
            onClick={() => setShowCreateShippingCompany(true)}
          >
            {t("general.create")}
          </Button>
        </Flex>

        <Flex marginBottom={8} justifyContent="flex-end">
          <InputSearch
            isLoading={shippingCompanies.isLoading}
            handleSearch={(query) => setQuery(query)}
          />

          <Filter
            handleFilter={(values) => setFilter(values)}
            handleClearFilter={() => setFilter(JSON.stringify({}))}
            disablesBtns={shippingCompanies.isLoading}
          />
        </Flex>
      </SimpleGrid>

      {shippingCompanies.data?.itemsCount > 0 ? (
        <ShippingCompaniesTable
          data={shippingCompanies.data.data}
          page={page}
          handleUpdate={(el) => setShowUpdateShippingCompany(el)}
          handleDelete={(el) => setShowDeleteShippingCompany(el)}
        />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={12}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color={theme.text}
            fontWeight="bold"
          >
            {t("pages.shipping_companies.no_shipping_companies")}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={shippingCompanies.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

      {showCreateShippingCompany && (
        <CreateModal onClose={() => setShowCreateShippingCompany(false)} />
      )}
      {showUpdateShippingCompany && (
        <UpdateModal
          data={showUpdateShippingCompany}
          onClose={() => {
            setShowUpdateShippingCompany(false);
            dispatch(getShippingCompanies({ page, query, filter }));
          }}
        />
      )}
      {showDeleteShippingCompany && (
        <DeleteModal
          data={showDeleteShippingCompany}
          onClose={() => setShowDeleteShippingCompany(false)}
        />
      )}
    </ShippingCompaniesWrapper>
  );
};

export default ShippngCompanies;
