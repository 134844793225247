import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyAFeB3_01CpOrzqlcVJpPJzXJQbm_sqzBU",
  authDomain: "safka-bd93c.firebaseapp.com",
  projectId: "safka-bd93c",
  storageBucket: "safka-bd93c.firebasestorage.app",
  messagingSenderId: "303770636035",
  appId: "1:303770636035:web:5bd33bf5dc4065ac4fe336",
  measurementId: "G-XFEYS0J995",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging
export const messaging = getMessaging(app);
