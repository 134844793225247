import React, { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  SimpleGrid,
  Flex,
  Select,
  Box,
  Switch,
} from "@chakra-ui/react";

import Search from "../../../shared/search/Search";

import { getShippingCompanies } from "../../../../store/shippingCompanies/shippingCompaniesSlice";
import { getWarehouses } from "../../../../store/warehouses/warehousesSlice";
import { getPriceList } from "../../../../store/priceList/priceListSlice";

import theme from "../../../global/theme";
import { getProducts } from "../../../../store/products/productsSlice";
import { getUsers, getAdmins } from "../../../../store/users/usersSlice";
import { getModerators } from "./../../../../store/moderators/moderatorsSlice";
import useAuth from "../../../../hooks/useAuth";
import { getDepartments } from "../../../../store/departments/departmentsSlice";

const Filter = ({ handleFilter, handleClearFilter, disablesBtns }) => {
  const { t } = useTranslation();
  const { register, handleSubmit, getValues, setValue, watch } = useForm();
  const { checkPermissions } = useAuth();

  watch([
    "shipping_company",
    "shipping_governorate",
    "warehouse",
    "product",
    "marketer",
    "assigned_to",
    "moderator",
  ]);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const shippingCompanies = useSelector((state) => state.shippingCompanies);
  const priceList = useSelector((state) => state.priceList);
  const warehouses = useSelector((state) => state.warehouses);
  const products = useSelector((state) => state.products);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const departments = useSelector((state) => state.departments);

  useEffect(() => {
    dispatch(getDepartments({}));
  }, [dispatch]);
  const markters = useSelector((state) => state.users);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter] = useState(
    JSON.stringify({ role: "marketer", is_active: true })
  );
  const moderators = useSelector((state) => state.moderators);

  const [pageModerator, setPageModerator] = useState(1);
  const [queryModerator, setQueryModerator] = useState("");
  const [filterModerator] = useState(JSON.stringify({}));

  useEffect(() => {
    if (getValues("marketer")?._id || auth.user?.role === "marketer") {
      dispatch(
        getModerators({
          page: pageModerator,
          query: queryModerator,
          filter: filterModerator,
          main_account: getValues("marketer")?._id,
        })
      );
    }
  }, [
    dispatch,
    pageModerator,
    queryModerator,
    filterModerator,
    getValues("marketer"),
    auth,
  ]);

  useEffect(() => {
    dispatch(getUsers({ page, query, filter }));
  }, [dispatch, page, query, filter]);

  const [priceListPage, setPriceListPage] = useState(1);
  const [priceListQuery, setPriceListQuery] = useState("");
  const [priceListFilter] = useState(JSON.stringify({ is_active: true }));
  useEffect(() => {
    dispatch(
      getPriceList({
        page: priceListPage,
        size: 50,
        query: priceListQuery,
        filter: priceListFilter,
      })
    );
  }, [dispatch, priceListPage, priceListQuery, priceListFilter]);

  const [productsPage, setProductsPage] = useState(1);
  const [productsQuery, setProductsQuery] = useState("");
  const productsDefaultFilter = { is_active: true };
  const [productsFilter] = useState(JSON.stringify(productsDefaultFilter));
  useEffect(() => {
    dispatch(
      getProducts({
        page: productsPage,
        query: productsQuery,
        filter: productsFilter,
      })
    );
  }, [dispatch, productsPage, productsQuery, productsFilter]);

  const [shippingCompaniesPage, setShippingCompaniesPage] = useState(1);
  const [shippingCompaniesQuery, setShippingCompaniesQuery] = useState("");
  useEffect(() => {
    dispatch(
      getShippingCompanies({
        page: shippingCompaniesPage,
        query: shippingCompaniesQuery,
      })
    );
  }, [dispatch, shippingCompaniesPage, shippingCompaniesQuery]);

  const [warehousesPage, setWarehousesPage] = useState(1);
  const [warehousesQuery, setWarehousesQuery] = useState("");
  useEffect(() => {
    dispatch(getWarehouses({ page: warehousesPage, query: warehousesQuery }));
  }, [dispatch, warehousesPage, warehousesQuery]);
  const [pageAdmin, setPageAdmin] = useState(1);
  const [queryAdmin, setQueryAdmin] = useState("");
  useEffect(() => {
    let filterByDepartment = {};
    if (selectedDepartment) {
      filterByDepartment = { department: selectedDepartment };
    }
    dispatch(
      getAdmins({
        page: pageAdmin,
        query: queryAdmin,
        role: "admin",
        filter: JSON.stringify({
          role: "admin",
          is_active: true,
          ...filterByDepartment,
        }),
      })
    );
  }, [dispatch, pageAdmin, queryAdmin, selectedDepartment]);
  return (
    <form
      onSubmit={handleSubmit((values) => {
        let finalValues = {};

        for (let prop in values) {
          if (values[prop]) {
            if (prop === "shipping_governorate") {
              finalValues[prop] = values[prop]?._id;
            } else if (prop === "warehouse") {
              finalValues[prop] = values[prop]?._id;
            } else if (prop === "shipping_company") {
              finalValues[prop] = values[prop]?._id;
            } else if (prop === "product") {
              finalValues[prop] = values[prop]?._id;
            } else if (prop === "marketer" || prop === "assigned_to") {
              finalValues[prop] = values[prop]?._id;
            } else if (prop === "moderator") {
              finalValues[prop] = values[prop]?._id;
            } else {
              if (
                prop === "from" ||
                prop === "to" ||
                prop === "updatedTo" ||
                prop === "updatedFrom"
              ) {
                finalValues[prop] = new Date(values[prop]);
              } else {
                finalValues[prop] = values[prop];
              }
            }
          } else if (
            prop === "is_late" ||
            prop === "not_assigned" ||
            prop === "not_noted"
          ) {
            if (values.is_late) {
              finalValues.is_late = true;
            } else {
              finalValues.is_late = false;
            }
            if (values.not_assigned) {
              finalValues.not_assigned = true;
            } else {
              finalValues.not_assigned = false;
            }
            if (values.not_noted) {
              finalValues.not_noted = true;
            } else {
              finalValues.not_noted = false;
            }
          }
        }

        handleFilter(finalValues);
      })}
    >
      <SimpleGrid
        columns={{ base: 2, md: 2, lg: 4 }}
        spacing={6}
        overflow={"hidden"}
        bg="#eee"
        p={2}
      >
        <FormControl>
          <FormLabel
            fontWeight="bold"
            textTransform="capitalize"
            color={theme.dark}
          >
            {t("pages.orders.shipping_governorate")}
          </FormLabel>
          <Search
            allowSearch={false}
            allowPagination={false}
            page={priceListPage}
            itemsCount={priceList.data?.itemsCount}
            data={priceList.data?.data}
            type="fromGovernorate"
            prop="governorate"
            currentElement={getValues("shipping_governorate")}
            handleChangePage={(page) => {
              setPriceListPage(page);
            }}
            handleChangeQuery={(query) => {
              setPriceListQuery(query);
            }}
            handleSelected={(item) => {
              setValue("shipping_governorate", item);
              setValue("city", "");
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel
            fontWeight="bold"
            textTransform="capitalize"
            color={theme.dark}
          >
            المنتجات
          </FormLabel>
          <Search
            page={productsPage}
            itemsCount={products.data?.itemsCount}
            data={products.data?.data}
            prop="name"
            currentElement={getValues(`product`)}
            handleChangePage={(page) => {
              setProductsPage(page);
            }}
            handleChangeQuery={(query) => {
              setProductsQuery(query);
            }}
            handleSelected={(item) => {
              // check if the product selected

              setValue("product", item);
            }}
          />
        </FormControl>
        {auth.user.role === "admin" && (
          <>
            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color={theme.dark}
              >
                {t("pages.orders.shipping_company")}
              </FormLabel>
              <Search
                page={shippingCompaniesPage}
                itemsCount={shippingCompanies.data?.itemsCount}
                data={shippingCompanies.data?.data}
                prop="name"
                currentElement={getValues("shipping_company")}
                handleChangePage={(page) => {
                  setShippingCompaniesPage(page);
                }}
                handleChangeQuery={(query) => {
                  setShippingCompaniesQuery(query);
                }}
                handleSelected={(item) => setValue("shipping_company", item)}
              />
            </FormControl>
            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color={theme.dark}
              >
                المخزن
              </FormLabel>
              <Search
                page={warehousesPage}
                itemsCount={warehouses.data?.itemsCount}
                data={warehouses.data?.data}
                prop="name"
                currentElement={getValues("warehouse")}
                handleChangePage={(page) => {
                  setWarehousesPage(page);
                }}
                handleChangeQuery={(query) => {
                  setWarehousesQuery(query);
                }}
                handleSelected={(item) => setValue("warehouse", item)}
              />
            </FormControl>

            <FormControl>
              <FormLabel fontWeight="bold" textTransform="capitalize">
                حالة الاوردر
              </FormLabel>
              <Select
                bg={theme.bg}
                color={theme.dark}
                {...register("action.status")}
              >
                <option style={{ color: theme.dark }} value="">
                  {t("general.select")}
                </option>
                {[
                  { preparing: "جار التحضيير" },
                  { shipped: "في الشحن" },
                  { available: "تم التوصيل" },
                  { collected: "تم التحصيل" },
                  { skip: "جار الاسترجاع" },
                  { holding: "مؤجل" },
                  { ask_to_return: "طلب العميل الإسترجاع" },
                  { returned1: "مرتجع" },
                  { returned2: "مرتجع بعد التسليم" },
                  { declined1: "ملغي" },
                  { declined2: "ملغية بعد التحضير" },
                  { cancelled: "معلومات غير صحيحة" },
                ].map((item) => {
                  const key = Object.keys(item)[0];
                  return (
                    <option key={key} style={{ color: theme.dark }} value={key}>
                      {item[key]}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color={theme.dark}
              >
                المسوق
              </FormLabel>
              <Search
                page={page}
                itemsCount={markters.data?.itemsCount}
                data={markters.data?.data}
                prop="name"
                currentElement={getValues("marketer")}
                handleChangePage={(page) => {
                  setPage(page);
                }}
                handleChangeQuery={(query) => {
                  setQuery(query);
                }}
                handleSelected={(item) => setValue("marketer", item)}
              />
            </FormControl>
            {checkPermissions("distribute_orders") && (
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color={theme.dark}
                >
                  الموظف
                </FormLabel>
                {/* <FormControl>
                  <FormLabel
                    fontWeight="bold"
                    textTransform="capitalize"
                    color="white"
                  >
                    اختر القسم
                  </FormLabel>
                  <Select
                    bg={theme.bg}
                    color={theme.dark}
                    value={selectedDepartment}
                    border="none"
                    borderRadius={4}
                    placeholder={"اختر القسم"}
                    onChange={(e) => setSelectedDepartment(e.target.value)}
                  >
                    {departments?.data?.data?.map((dept) => (
                      <option key={dept._id} value={dept._id}>
                        {dept.name}
                      </option>
                    ))}
                  </Select>
                </FormControl> */}
                <Search
                  page={pageAdmin}
                  itemsCount={markters.admins?.itemsCount}
                  data={markters.admins?.data}
                  prop="name"
                  currentElement={getValues("assigned_to")}
                  handleChangePage={(page) => {
                    setPageAdmin(page);
                  }}
                  handleChangeQuery={(query) => {
                    setQueryAdmin(query);
                  }}
                  handleSelected={(item) => setValue("assigned_to", item)}
                />
              </FormControl>
            )}
          </>
        )}
        {(auth.user.role === "admin" || auth.user.role === "marketer") && (
          <>
            {(getValues("marketer") || auth.user.role === "marketer") && (
              <FormControl>
                <FormLabel
                  fontWeight="bold"
                  textTransform="capitalize"
                  color={theme.dark}
                >
                  المودريتر
                </FormLabel>
                <Search
                  page={page}
                  itemsCount={moderators.data?.itemsCount}
                  data={moderators.data?.data}
                  prop="name"
                  currentElement={getValues("moderator")}
                  handleChangePage={(page) => {
                    setPageModerator(page);
                  }}
                  handleChangeQuery={(query) => {
                    setQueryModerator(query);
                  }}
                  handleSelected={(item) => setValue("moderator", item)}
                />
              </FormControl>
            )}
            <FormControl>
              <FormLabel fontWeight="bold" textTransform="capitalize">
                سبب إرجاع الاوردر
              </FormLabel>
              <Select
                bg={theme.bg}
                color={theme.dark}
                {...register("skipping_reason")}
              >
                <option style={{ color: theme.dark }} value="">
                  {t("general.select")}
                </option>
                <option style={{ color: theme.dark }} value="refused_with_rep">
                  العميل رفض الشحنة مع المندوب
                </option>
                <option
                  style={{ color: theme.dark }}
                  value="no_response_to_rep"
                >
                  العميل لا يرد علي المندوب ولا علي خدمة العملاء
                </option>
                <option style={{ color: theme.dark }} value="client_evaded">
                  العميل تهرب من الإستلام
                </option>
                <option
                  style={{ color: theme.dark }}
                  value="cancelled_with_rep"
                >
                  لغي مع مندوب الشحن وعند التواصل لمعرفة السبب لا يرد العميل
                </option>
                <option
                  style={{ color: theme.dark }}
                  value="refused_to_receive"
                >
                  رفض الإستلام وعند تواصل خدمة العملاء الرقم غير متاح
                </option>
                <option
                  style={{ color: theme.dark }}
                  value="canceled_via_phone"
                >
                  العميل بلغ المندوب في التليفون بالإلغاء
                </option>
                <option
                  style={{ color: theme.dark }}
                  value="received_elsewhere"
                >
                  العميل استلم من مكان تاني
                </option>
                <option style={{ color: theme.dark }} value="other_reason">
                  سبب آخر
                </option>
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color={theme.dark}
              >
                سبب الإلغاء
              </FormLabel>
              <Select
                bg={theme.bg}
                color={theme.dark}
                {...register("canceling_reason")}
              >
                <option style={{ color: theme.dark }} value="">
                  {t("general.select")}
                </option>
                <option
                  style={{ color: theme.dark }}
                  value="purchased_elsewhere"
                >
                  العميل اشتري من مكان تاني
                </option>
                <option style={{ color: theme.dark }} value="not_needed_now">
                  مش محتاجه دلوقتي هبقي اطلبه تاني
                </option>
                <option style={{ color: theme.dark }} value="denied_order">
                  العميل بيقول ما طلبتش حاجة
                </option>
                <option style={{ color: theme.dark }} value="unable_to_reach">
                  صعوبة الوصول للعميل
                </option>
                <option style={{ color: theme.dark }} value="price_issue">
                  بسبب السعر
                </option>
                <option style={{ color: theme.dark }} value="repeated_order">
                  طلب مكرر
                </option>
                <option style={{ color: theme.dark }} value="other_reason">
                  سبب آخر
                </option>
              </Select>
            </FormControl>
          </>
        )}
        <FormControl>
          <FormLabel
            fontWeight="bold"
            textTransform="capitalize"
            color={theme.dark}
          >
            (من)تاريخ الإنشاء
          </FormLabel>
          <Input
            type="date"
            bg={theme.bg}
            color={theme.dark}
            border="none"
            borderRadius={4}
            placeholder={t("general.from")}
            _placeholder={{ textTransform: "capitalize" }}
            {...register("from")}
          />
        </FormControl>

        <FormControl>
          <FormLabel
            fontWeight="bold"
            textTransform="capitalize"
            color={theme.dark}
          >
            {t("general.to")}
          </FormLabel>
          <Input
            type="date"
            bg={theme.bg}
            color={theme.dark}
            border="none"
            borderRadius={4}
            placeholder={t("general.to")}
            _placeholder={{ textTransform: "capitalize" }}
            {...register("to")}
          />
        </FormControl>
        <FormControl>
          <FormLabel
            fontWeight="bold"
            textTransform="capitalize"
            color={theme.dark}
          >
            (من) تاريخ أخر تحديث
          </FormLabel>
          <Input
            type="date"
            bg={theme.bg}
            color={theme.dark}
            border="none"
            borderRadius={4}
            placeholder={t("general.from")}
            _placeholder={{ textTransform: "capitalize" }}
            {...register("updatedFrom")}
          />
        </FormControl>

        <FormControl>
          <FormLabel
            fontWeight="bold"
            textTransform="capitalize"
            color={theme.dark}
          >
            {t("general.to")}
          </FormLabel>
          <Input
            type="date"
            bg={theme.bg}
            color={theme.dark}
            border="none"
            borderRadius={4}
            placeholder={t("general.to")}
            _placeholder={{ textTransform: "capitalize" }}
            {...register("updatedTo")}
          />
        </FormControl>
        {auth.user.role === "admin" && (
          <FormControl
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex alignItems="center" gap={2}>
              <FormLabel m={0} fontWeight="bold" textTransform="capitalize">
                الطلبات المتأخرة
              </FormLabel>
              <Switch
                colorScheme="teal"
                size="lg"
                type="checkbox"
                color={theme.light}
                {...register("is_late")}
              />
            </Flex>
          </FormControl>
        )}
        {checkPermissions("distribute_orders") && (
          <FormControl
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex alignItems="center" gap={2}>
              <FormLabel m={0} fontWeight="bold" textTransform="capitalize">
                الطلبات غير المتوزعة
              </FormLabel>
              <Switch
                colorScheme="teal"
                size="lg"
                type="checkbox"
                color={theme.light}
                {...register("not_assigned")}
              />
            </Flex>
          </FormControl>
        )}
        {auth.user.role === "admin" && (
          <FormControl
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex alignItems="center" gap={2}>
              <FormLabel m={0} fontWeight="bold" textTransform="capitalize">
                طلبات بدون ملاحظات
              </FormLabel>
              <Switch
                colorScheme="teal"
                size="lg"
                type="checkbox"
                color={theme.light}
                {...register("not_noted")}
              />
            </Flex>
          </FormControl>
        )}
        <Flex alignItems="flex-end">
          <Button
            type="submit"
            isLoading={disablesBtns}
            mt={2}
            width="100%"
            colorScheme="blue"
            me={2}
          >
            فلتر
          </Button>
          <Button
            type="button"
            isLoading={disablesBtns}
            mt={2}
            width="100%"
            colorScheme="red"
            onClick={() => {
              handleClearFilter();
              setValue("warehouse", "");
              setValue("shipping_company", "");
              setValue("shipping_governorate", "");
              setValue("city", "");
              setValue("product", "");
              setValue("moderator", "");
              setValue("updatedFrom", "");
              setValue("updatedTo", "");
              setValue("from", "");
              setValue("to", "");
              setValue("marketer", "");
              setValue("assigned_to", "");
              setValue("reason_category", "");
              setValue("is_late", false);
              setValue("not_assigned", false);
              setValue("not_noted", false);
            }}
          >
            استعادة
          </Button>
        </Flex>
      </SimpleGrid>
    </form>
  );
};

export default memo(Filter);
