import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";

import Table from "../../shared/table/Table";
import theme from "../../global/theme";

const ShippingCompaniesTable = ({ data, page, handleUpdate, handleDelete }) => {
  const { t } = useTranslation();
  return (
    <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>{t("pages.shipping_companies.name")}</th>
          <th>{t("pages.shipping_companies.site")}</th>
          <th>{t("general.action")}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr key={el._id}>
            <td>{(page - 1) * 10 + (index + 1)}</td>
            <td>{el.name}</td>
            <td>{el.site}</td>
            <td>
              <Button
                bg="transparent"
                color={theme.success}
                size="xs"
                _hover={{ bg: "transparent" }}
                onClick={() => handleUpdate(el)}
              >
                <FiIcons.FiEdit size={20} />
              </Button>

              <Button
                bg="transparent"
                color={theme.error}
                size="xs"
                _hover={{ bg: "transparent" }}
                onClick={() => handleDelete(el)}
              >
                <FiIcons.FiDelete size={20} />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ShippingCompaniesTable;
