import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  SimpleGrid,
  Flex,
  Divider,
  Center,
} from "@chakra-ui/react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import {
  getBlacklist,
  createBlacklist,
  updateBlacklist,
  deleteBlacklist,
  getPossibleBlacklist,
} from "./../../../store/blacklist/blacklistSlice";
import Pagination from "../../shared/pagination/Pagination";
import InputSearch from "../../shared/inputSearch/InputSearch";
import { useNavigate } from "react-router-dom";

const Blacklist = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, data, possible, errors } = useSelector(
    (state) => state.blacklist
  );
  const toast = useToast();

  const [formState, setFormState] = useState({ phone: "" });
  const [editingId, setEditingId] = useState(null);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    dispatch(getPossibleBlacklist({}));
  }, []);

  useEffect(() => {
    dispatch(getBlacklist({ page, query }));
  }, [dispatch, page, query]);

  useEffect(() => {
    if (errors.length) {
      toast({
        title: "خطأ",
        description: "حدث خطأ في البيانات",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [errors, toast]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    if (editingId) {
      dispatch(updateBlacklist({ _id: editingId, values: formState }));
    } else {
      dispatch(createBlacklist(formState));
    }
    dispatch(getBlacklist({ page }));
    dispatch(getPossibleBlacklist({}));

    setEditingId(null);
    onClose();
    setFormState({ phone: "" });
  };

  const handleEdit = (blacklist) => {
    setFormState({ phone: blacklist.phone });
    setEditingId(blacklist._id);
    onOpen();
  };

  const handleDelete = (id) => {
    dispatch(deleteBlacklist({ _id: id }));
    dispatch(getBlacklist({ page }));
    dispatch(getPossibleBlacklist({}));
  };

  return (
    <Box p={6} borderRadius="md">
      <Heading mb={6} size="lg" textAlign="center">
        قائمة الأرقام المحظورة
      </Heading>

      <Flex
        justify="space-between"
        flexWrap="wrap"
        gap={2}
        align="center"
        mb={4}
      >
        <Button onClick={onOpen} colorScheme="blue">
          + إضافة رقم هاتف جديد
        </Button>
        <InputSearch
          isLoading={isLoading}
          handleSearch={(query) => {
            setQuery(query);
            setPage(1);
          }}
        />
      </Flex>

      <Divider my={4} />

      {isLoading ? (
        <Center py={10}>
          <Spinner size="xl" />
        </Center>
      ) : (
        <SimpleGrid columns={{ sm: 1, lg: 2 }} spacing={6}>
          {/* Blacklist Table */}
          <Box overflowX="auto">
            <Heading size="md" mb={3}>
              الأرقام المحظورة
            </Heading>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>رقم الهاتف</Th>
                  <Th>إجراءات</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.data?.data?.map((blacklist) => (
                  <Tr key={blacklist._id}>
                    <Td>{blacklist.phone}</Td>
                    <Td>
                      <Flex gap={2}>
                        <Button
                          size="sm"
                          colorScheme="yellow"
                          onClick={() => handleEdit(blacklist)}
                        >
                          تعديل
                        </Button>
                        <Button
                          size="sm"
                          colorScheme="red"
                          onClick={() => handleDelete(blacklist._id)}
                        >
                          حذف
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Pagination
              page={page}
              itemsCount={data.data?.itemsCount ?? 0}
              onChange={(page) => setPage(page)}
            />
          </Box>

          <Box width="100%" overflowX="scroll">
            <Accordion allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="right" fontWeight="bold">
                      الأرقام المحتملة للحظر
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Box overflowX="auto">
                    <Table variant="compact">
                      <Thead>
                        <Tr>
                          <Th>رقم الهاتف</Th>
                          <Th>عدد الطلبات المرتجعة</Th>
                          <Th>إضافة</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {possible?.data
                          ?.filter((blacklist) => blacklist.phone !== "")
                          ?.map((blacklist) => (
                            <Tr key={blacklist._id}>
                              <Td>
                                <Button
                                  variant="link"
                                  colorScheme="blue"
                                  onClick={() =>
                                    navigate(
                                      `/orders?page=1&size=20&queryPhone=${blacklist.phone}&status=`
                                    )
                                  }
                                >
                                  {blacklist.phone}
                                </Button>
                              </Td>
                              <Td>{blacklist.returned1_count}</Td>
                              <Td>
                                {!blacklist.is_blacklisted && (
                                  <Button
                                    onClick={() => {
                                      setFormState({ phone: blacklist.phone });
                                      onOpen();
                                    }}
                                    colorScheme="blue"
                                  >
                                    إضافة
                                  </Button>
                                )}
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </Box>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </SimpleGrid>
      )}

      {/* Modal for Add/Edit Blacklist */}
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setFormState({ phone: "" });
          setEditingId(null);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editingId ? "تعديل الرقم" : "إضافة رقم جديد"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>رقم الهاتف</FormLabel>
              <Input
                name="phone"
                maxLength={11}
                value={formState.phone}
                onChange={handleInputChange}
                placeholder="ادخل رقم الهاتف"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSubmit} mr={3}>
              {editingId ? "تحديث" : "إضافة"}
            </Button>
            <Button variant="ghost" onClick={onClose}>
              إلغاء
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Blacklist;
