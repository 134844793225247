import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import * as FiIcons from "react-icons/fi";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Flex,
  useToast,
} from "@chakra-ui/react";

import Search from "../../../shared/search/Search";

import { getShippingCompanies } from "../../../../store/shippingCompanies/shippingCompaniesSlice";
import { updateOrderShippingCompany } from "../../../../store/orders/ordersSlice";

import theme from "../../../global/theme";

const UpdateShippingCompanyModal = ({ data, onClose }) => {
  const toast = useToast({ duration: 2000, position: "top", status: "error" });
  const toastSuccess = useToast({
    duration: 2000,
    position: "top",
    status: "success",
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const shippingCompanies = useSelector((state) => state.shippingCompanies);
  const orders = useSelector((state) => state.orders);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");

  useEffect(() => {
    dispatch(getShippingCompanies({ page, query }));
  }, [dispatch, page, query]);

  const { setValue, handleSubmit, getValues, watch } = useForm({
    defaultValues: {
      orders: data,
    },
  });

  watch(["shipping_company"]);

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius={20} padding={4} bg={theme.dark}>
        <form
          onSubmit={handleSubmit((values) => {
            const finalValues = values;
            finalValues["shipping_company"] =
              values.shipping_company?._id ?? null;
            console.log(finalValues);
            dispatch(updateOrderShippingCompany(finalValues))
              .unwrap()
              .then((_) => {
                toastSuccess({ description: "تم تحديث شركة الشحن بنجاح" });
                onClose();
                // window.location.reload();
              })
              .catch((_) => {
                onClose();
              });
          })}
        >
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            {t("general.update")}
          </ModalHeader>
          <ModalBody>
            <Search
              page={page}
              itemsCount={shippingCompanies.data?.itemsCount}
              data={shippingCompanies.data?.data}
              prop="name"
              currentElement={getValues("shipping_company")}
              handleChangePage={(page) => {
                setPage(page);
              }}
              handleChangeQuery={(query) => {
                setQuery(query);
              }}
              handleSelected={(item) => setValue("shipping_company", item)}
            />
          </ModalBody>

          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white"
                bg="green"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                isLoading={orders.isLoading}
                _hover={{ background: "green" }}
              >
                {t("general.save")}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white"
                bg="red.600"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                marginInlineStart={4}
                isLoading={orders.isLoading}
                _hover={{ background: "red.600" }}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UpdateShippingCompanyModal;
