import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  Stack,
  SimpleGrid,
  Box,
  Flex,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import { updateShippingCompany } from "../../../../store/shippingCompanies/shippingCompaniesSlice";
import theme from "../../../global/theme";
import { governorates } from "../../../../utilities/places";

const ShippingCompanyModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.shippingCompanies);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...data,
      governorates: governorates.map((gov) => {
        const existing = data?.governorates?.find(
          (g) => g.governorate == gov.id
        );
        return {
          governorate: gov.id,
          priceShipping: existing?.priceShipping || 0,
          priceSkipping: existing?.priceSkipping || 0,
        };
      }),
    },
  });

  const onSubmit = (values) => {
    const transformedData = {
      ...values,
      percentage: Number(values.percentage) || 0,
      amountToApply: Number(values.amountToApply) || 0,
      governorates: values.governorates.map((g) => ({
        governorate: Number(g.governorate),
        priceShipping: Number(g.priceShipping) || 0,
        priceSkipping: Number(g.priceSkipping) || 0,
      })),
    };

    dispatch(updateShippingCompany({ id: data._id, values: transformedData }))
      .unwrap()
      .then(() => onClose())
      .catch((error) => console.error("Update failed:", error));
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} bg={theme.dark}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            {t("general.update")}
          </ModalHeader>

          <ModalBody>
            <Stack spacing={4}>
              {/* Company Details */}
              <SimpleGrid columns={2} spacing={4}>
                <FormControl>
                  <FormLabel color={theme.light}>
                    {t("pages.shipping_companies.name")}
                  </FormLabel>
                  <Input
                    bg={theme.bg}
                    {...register("name", {
                      required: t("validation.required"),
                    })}
                  />
                  {errors.name && (
                    <Text color="red.500">{errors.name.message}</Text>
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel color={theme.light}>
                    {t("pages.shipping_companies.site")}
                  </FormLabel>
                  <Input
                    bg={theme.bg}
                    {...register("site", {
                      required: t("validation.required"),
                    })}
                  />
                  {errors.site && (
                    <Text color="red.500">{errors.site.message}</Text>
                  )}
                </FormControl>
              </SimpleGrid>

              {/* Pricing Settings */}
              <SimpleGrid columns={2} spacing={4} mt={4}>
                <FormControl>
                  <FormLabel color={theme.light}>
                    النسبة المئوية الإضافية
                  </FormLabel>
                  <Input
                    type="number"
                    bg={theme.bg}
                    {...register("percentage", { valueAsNumber: true })}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel color={theme.light}>التسعيرة</FormLabel>
                  <Input
                    type="number"
                    bg={theme.bg}
                    {...register("amountToApply", { valueAsNumber: true })}
                  />
                </FormControl>
              </SimpleGrid>

              {/* Governorates Pricing */}
              <Box mt={6}>
                <SimpleGrid columns={3} spacing={2}>
                  <Box
                    color="white"
                    marginInline={5}
                    me={2}
                    mb={3}
                    fontWeight="bold"
                  >
                    المحافظة
                  </Box>
                  <Box
                    color="white"
                    marginInline={5}
                    me={2}
                    mb={3}
                    fontWeight="bold"
                  >
                    سعر الشحن
                  </Box>
                  <Box
                    color="white"
                    marginInline={5}
                    me={2}
                    mb={3}
                    fontWeight="bold"
                  >
                    سعر الإسترجاع
                  </Box>
                  {governorates.map((gov, index) => (
                    <React.Fragment key={gov.id}>
                      <input
                        type="hidden"
                        {...register(`governorates.${index}.governorate`)}
                        value={gov.id}
                      />

                      <FormControl>
                        <Input
                          value={gov.governorate_name_ar}
                          readOnly
                          bg="white"
                        />
                      </FormControl>

                      <FormControl>
                        <Input
                          type="number"
                          bg="white"
                          {...register(`governorates.${index}.priceShipping`, {
                            valueAsNumber: true,
                          })}
                        />
                      </FormControl>

                      <FormControl>
                        <Input
                          type="number"
                          bg="white"
                          {...register(`governorates.${index}.priceSkipping`, {
                            valueAsNumber: true,
                          })}
                        />
                      </FormControl>
                    </React.Fragment>
                  ))}
                </SimpleGrid>
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Flex justifyContent="space-between" alignItems="center" w="100%">
              <Button
                type="submit"
                colorScheme="blue"
                rightIcon={<FiIcons.FiSave />}
                isLoading={isLoading}
                mr={3}
              >
                {t("general.save")}
              </Button>
              <Button
                colorScheme="red"
                onClick={onClose}
                rightIcon={<FiIcons.FiX />}
                isDisabled={isLoading}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ShippingCompanyModal;
