import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  SimpleGrid,
  Box,
  Input,
  Flex,
  Heading,
  Divider,
} from "@chakra-ui/react";
import { FiDelete, FiPlus, FiSave, FiX } from "react-icons/fi";
import { useForm, useFieldArray } from "react-hook-form";
import theme from "../../../global/theme";
import useAuth from "../../../../hooks/useAuth";

const EditProductStock = ({ isOpen, onClose, selectedProduct, onSubmit }) => {
  const { checkPermissions } = useAuth();
  const { control, handleSubmit, register, setValue, getValues, watch } =
    useForm({
      defaultValues: {
        properties: selectedProduct?.properties || [],
      },
    });
  useEffect(() => {
    setValue("properties", selectedProduct?.properties || []);
  }, [selectedProduct]);
  watch(["properties"]);
  const {
    fields: properties,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "properties",
  });

  const handleAdjustment = (index) => {
    const currentStock = getValues(`properties.${index}.real_stock`) || 0;
    const newStock = getValues(`properties.${index}.adjustmentValue`) || 0;

    // Update the real_stock value
    setValue(
      `properties.${index}.real_stock`,
      Number(currentStock) + Number(newStock)
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader textAlign="center" color="orange" fontSize={22}>
            تعديل المنتج
          </ModalHeader>
          <ModalBody>
            {checkPermissions("fake_stock", "manage_products") && (
              <>
                <SimpleGrid
                  columns={{ base: 2, md: 3, lg: 4 }}
                  border={`1px solid ${theme.border}`}
                  gridTemplateColumns={{
                    base: "repeat(2, 1fr)",
                    md: "repeat(3, 1fr)",
                    lg: "1fr repeat(3, 150px)",
                  }}
                  alignItems="center"
                  paddingBlock={2}
                  mb={2}
                  borderRadius={4}
                  spacing={2}
                  spacingY={2}
                  spacingX={2}
                  gap={2}
                >
                  {properties.map((el, index) => (
                    <React.Fragment key={el.id}>
                      <Box marginInline={5} me={2} mb={3}>
                        <Input
                          bg="white"
                          type="text"
                          minW="170px"
                          required
                          {...register(`properties.${index}.key`)}
                          placeholder="مثال: لون احمر مقاس لارج"
                        />
                      </Box>

                      <Box marginInline={5} mb={3}>
                        <Input
                          bg="white"
                          type="text"
                          minW="30px"
                          {...register(`properties.${index}.value`)}
                          placeholder="ستوك وهمي مثلا: 5"
                        />
                      </Box>
                      <Box marginInline={5} mb={3}>
                        <Input
                          bg="white"
                          type="text"
                          minW="30px"
                          {...register(`properties.${index}.min`)}
                          placeholder="الحد الأدني للتنبيه"
                        />
                      </Box>
                      <Flex spacing={4} mb={3}>
                        <Button
                          type="button"
                          bg={theme.success}
                          color={theme.light}
                          onClick={() => append({})}
                          me={2}
                        >
                          <FiPlus />
                        </Button>
                        <Button
                          type="button"
                          bg={theme.error}
                          color={theme.light}
                          onClick={() => remove(index)}
                        >
                          <FiDelete />
                        </Button>
                      </Flex>
                    </React.Fragment>
                  ))}
                </SimpleGrid>
                <Divider marginBlock={6} />
              </>
            )}
            {checkPermissions("real_stock", "manage_products") && (
              <>
                <Heading size="md" mb={4} color={theme.light}>
                  الاستوك الفعلي
                </Heading>
                <SimpleGrid
                  columns={{ sm: 1, md: 2, lg: 3 }}
                  spacing={6}
                  mb={4}
                  borderRadius={6}
                >
                  {properties.map((el, index) => (
                    <Flex
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      gap={2}
                      key={el.id}
                      border={`1px solid ${theme.border}`}
                      padding={4}
                      borderRadius={8}
                      required
                      boxShadow="sm"
                      bg={theme.background}
                      position="relative"
                    >
                      <Box>
                        <span
                          style={{
                            fontSize: "0.8rem",
                            fontWeight: "bold",
                            color: theme.light,
                          }}
                        >
                          الخاصية
                        </span>
                        <Input
                          bg="white"
                          type="text"
                          value={getValues(`properties.${index}.key`)}
                          placeholder="مثال: لون احمر مقاس لارج"
                          size="sm"
                          readOnly
                        />
                      </Box>

                      <Box>
                        <span
                          style={{
                            fontSize: "0.8rem",
                            fontWeight: "bold",
                            color: theme.light,
                          }}
                        >
                          الكمية الفعلية
                        </span>
                        <Input
                          bg="white"
                          type="text"
                          // {...register(`properties.${index}.real_stock`)}
                          value={getValues(`properties.${index}.real_stock`)}
                          placeholder="اكتب كمية مثلا: 5"
                          size="sm"
                          readOnly
                        />
                      </Box>

                      <Box>
                        <span
                          style={{
                            fontSize: "0.8rem",
                            fontWeight: "bold",
                            color: theme.light,
                          }}
                        >
                          وراد/مرتجع
                        </span>
                        <Input
                          bg="white"
                          type="number"
                          placeholder="أدخل الكمية (+ أو -) الكمية"
                          size="sm"
                          {...register(`properties.${index}.adjustmentValue`)}
                        />
                      </Box>
                      <Box>
                        <span
                          style={{
                            fontSize: "0.8rem",
                            fontWeight: "bold",
                            color: theme.light,
                          }}
                        >
                          ملاحظة
                        </span>
                        <Input
                          bg="white"
                          type="text"
                          placeholder="ملاحظة (إختياري) "
                          size="sm"
                          {...register(`properties.${index}.note`)}
                        />
                      </Box>
                      <Box>
                        <Button
                          type="button"
                          bg={theme.primary}
                          color={theme.light}
                          onClick={() => handleAdjustment(index)}
                          size="sm"
                        >
                          تأكيد
                        </Button>
                      </Box>
                    </Flex>
                  ))}
                </SimpleGrid>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end" gap={4}>
              <Button type="submit" colorScheme="green" rightIcon={<FiSave />}>
                حفظ
              </Button>
              <Button onClick={onClose} colorScheme="red" rightIcon={<FiX />}>
                إغلاق
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default EditProductStock;
