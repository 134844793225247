import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, Button, Divider, Flex, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import * as FiIcons from "react-icons/fi";
import { MdCancel } from "react-icons/md";
import Table from "../../shared/table/Table";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { cities, governorates } from "../../../utilities/places";

import theme from "../../global/theme";
import { BiSolidFlag } from "react-icons/bi";
import useAuth from "../../../hooks/useAuth";
const systemRevenue = (items) => {
  let total = 0;
  for (let i = 0; i < items.length; i++) {
    const totalPurchasePrice = items[i].total_purchase_price;
    const totalSalePrice = items[i].total_sale_price;
    total += totalSalePrice - totalPurchasePrice;
  }
  return total;
};
const OrdersTable = ({
  data,
  query,
  handleUpdate,
  handleCancel,
  handleOrderStatus,
  handleSelectOrder,
  handleSelectAllOrders,
  showDetails = false,
}) => {
  const { checkPermissions } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const orders = useSelector((state) => state.orders);
  const [systemRevenueTotal, setSystemRevenueTotal] = useState(0);
  const toast = useToast({ status: "success", position: "top" });

  // Memoize the system revenue calculation
  const calculateSystemRevenue = useMemo(() => {
    const total = data.reduce((acc, el) => {
      const totalPurchasePrice = el.items.reduce(
        (sum, item) => sum + item.total_purchase_price,
        0
      );
      const totalSalePrice = el.items.reduce(
        (sum, item) => sum + item.total_sale_price,
        0
      );
      return acc + (totalSalePrice - totalPurchasePrice);
    }, 0);
    return total;
  }, [data]);

  useEffect(() => {
    setSystemRevenueTotal(calculateSystemRevenue);
  }, [calculateSystemRevenue]);

  const marketer = (
    <Table
      style={showDetails ? { display: "none" } : {}}
      id={showDetails ? "table-to-xls_orders" : ""}
    >
      <thead>
        <tr>
          <th>#</th>
          <th>{t("pages.orders.serial_number")}</th>
          <th>مودريتور</th>
          <th>{t("pages.orders.client_name")}</th>
          <th>{t("pages.orders.client_phone1")}</th>
          <th>{t("pages.orders.products")}</th>
          <th>{t("pages.orders.total")}</th>
          <th>{t("pages.orders.commission")}</th>
          <th>تاريخ الانشاء</th>
          <th>{t("pages.orders.last_update")}</th>
          <th>{t("pages.orders.governorate")}</th>
          {showDetails && <th>{t("pages.orders.note")}</th>}

          <th>{t("pages.orders.status")}</th>
          <th>{t("general.action")}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr key={el._id}>
            <td>{index + 1}</td>
            <td>
              <CopyToClipboard
                text={el.serial_number}
                onCopy={() => {
                  toast({
                    position: "top",
                    description: "تم نسخ كود الطلب",
                  });
                }}
              >
                <Box color="#5fd2ff" cursor={"pointer"}>
                  {el.serial_number}
                </Box>
              </CopyToClipboard>
            </td>
            <td>{el.moderator?.name || "-"}</td>
            <td>{el.client_name}</td>
            <td>
              <CopyToClipboard
                text={el.client_phone1}
                onCopy={() => {
                  toast({
                    position: "top",
                    description: "تم نسخ رقم الهاتف ",
                  });
                }}
              >
                <Box color="#5fd2ff" cursor={"pointer"}>
                  {el.client_phone1}
                </Box>
              </CopyToClipboard>
            </td>
            <td>
              {el.items?.length &&
                el.items.map(
                  (item, idx) =>
                    `(${item.qty}) قطعة ${item.product?.name} - ${
                      item.product.properties.find(
                        (it) => it._id === item.property
                      )?.key
                    } ${el.items?.length === idx + 1 ? "" : "--"} `
                )}
            </td>
            <td>{el.total}</td>
            <td>{el.commission}</td>
            <td>{new Date(el.created_at).toLocaleDateString()}</td>
            <td>{new Date(el.updated_at).toLocaleDateString()}</td>
            <td>
              {
                governorates.find(
                  (gov) => +gov.id === el.shipping_governorate?.governorate
                )?.governorate_name_ar
              }
            </td>
            {showDetails && <td>{el.note}</td>}

            <td>
              <Box
                as="span"
                borderRadius="5px"
                fontSize="12px"
                whiteSpace="nowrap"
                paddingBlock={1}
                paddingInline={2}
                color={theme.light}
                background={
                  el.status === "pending"
                    ? "yellow.400"
                    : el.status === "preparing"
                    ? "orange"
                    : el.status === "shipped"
                    ? "#0094B7"
                    : el.status === "available" || el.status === "collected"
                    ? "green"
                    : "red"
                }
              >
                {t(`pages.orders.${el.status}`)}
              </Box>
              {el.status === "holding" && el.holding_to && (
                <Box color={theme.dark} bg={theme.light}>
                  {el.status === "holding" && el.holding_to
                    ? new Date(el.holding_to).toLocaleString()
                    : ""}
                </Box>
              )}
            </td>
            <td>
              <Box>
                <Button
                  bg={theme.error}
                  fontSize="10px"
                  size="xs"
                  color={theme.light}
                  mb={4}
                  onClick={() =>
                    navigate(
                      `/orders/order_details/${el._id}?status=${el.status}`
                    )
                  }
                >
                  تفاصيل الطلب
                </Button>

                <Button
                  flex="1"
                  bg={theme.blue}
                  color={theme.light}
                  size="xs"
                  mb={2}
                  onClick={() => navigate(`/orders/${el._id}/notes`)}
                >
                  <Box position="relative">
                    <span>ملاحظات الطلب</span>
                    {el.replies.length > 0 && (
                      <Box
                        position="absolute"
                        top="-15px"
                        left="-1px"
                        w="15px"
                        h="15px"
                        bg={theme.error}
                        color={theme.light}
                        borderRadius="50%"
                        fontSize="6px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {el.replies?.length ?? 0}
                      </Box>
                    )}
                  </Box>
                </Button>
              </Box>

              {el.status !== "pending" && (
                <Button
                  type="button"
                  size="xs"
                  onClick={() => handleOrderStatus(el)}
                >
                  تتبع الطلب
                </Button>
              )}
              {el.status === "pending" &&
                // el?.replies?.length === 0 &&
                // el?.upsell1_clicked == 0 &&
                // el?.upsell2_clicked == 0 &&
                // el?.sms1_clicked == 0 &&
                // el?.sms2_clicked == 0 &&
                // el?.phone1_clicked == 0 &&
                // el?.phone2_clicked == 0 &&
                el?.whats1_clicked == 0 &&
                el?.whats2_clicked == 0 && (
                  <>
                    <Button
                      flex="1"
                      bg="transparent"
                      color="green"
                      size="xs"
                      mb={2}
                      onClick={() => handleUpdate(el)}
                    >
                      تعديل
                    </Button>
                    <Button
                      flex="1"
                      bg="transparent"
                      color="red"
                      size="xs"
                      mb={2}
                      onClick={() => handleCancel(el)}
                    >
                      إلغاء
                    </Button>
                  </>
                )}
            </td>
          </tr>
        ))}
        <tr>
          <td></td>
          <td>الإجمالي</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{orders?.data?.total}</td>
          <td>{orders?.data?.totalCommissions}</td>
        </tr>
      </tbody>
    </Table>
  );

  const moderator = (
    <Table
      style={showDetails ? { display: "none" } : {}}
      id={showDetails ? "table-to-xls_orders" : ""}
    >
      <thead>
        <tr>
          <th>#</th>
          <th>{t("pages.orders.serial_number")}</th>
          <th>{t("pages.orders.client_name")}</th>
          <th>{t("pages.orders.client_phone1")}</th>
          <th>{t("pages.orders.governorate")}</th>
          <th>{t("pages.orders.products")}</th>
          <th>{t("pages.orders.total")}</th>
          <th>تاريخ الانشاء</th>
          <th>{t("pages.orders.last_update")}</th>
          {showDetails && <th>{t("pages.orders.note")}</th>}

          <th>{t("pages.orders.status")}</th>
          <th>{t("general.action")}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr key={el._id}>
            <td>{index + 1}</td>
            <td>
              <CopyToClipboard
                text={el.serial_number}
                onCopy={() => {
                  toast({
                    position: "top",
                    description: "تم نسخ كود الطلب",
                  });
                }}
              >
                <Box color="#5fd2ff" cursor={"pointer"}>
                  {el.serial_number}
                </Box>
              </CopyToClipboard>
            </td>
            <td>{el.client_name}</td>
            <td>
              <CopyToClipboard
                text={el.client_phone1}
                onCopy={() => {
                  toast({
                    position: "top",
                    description: "تم نسخ رقم الهاتف ",
                  });
                }}
              >
                <Box color="#5fd2ff" cursor={"pointer"}>
                  {el.client_phone1}
                </Box>
              </CopyToClipboard>
            </td>
            <td>
              {
                governorates.find(
                  (gov) => +gov.id === el.shipping_governorate?.governorate
                )?.governorate_name_ar
              }
            </td>
            <td>
              {el.items?.length &&
                el.items.map(
                  (item, idx) =>
                    `(${item.qty}) قطعة ${item.product?.name} - ${
                      item.product.properties.find(
                        (it) => it._id === item.property
                      )?.key
                    } ${el.items?.length === idx + 1 ? "" : "--"} `
                )}
            </td>
            <td>{el.total}</td>
            <td>{new Date(el.created_at).toLocaleDateString()}</td>
            <td>{new Date(el.updated_at).toLocaleDateString()}</td>
            {showDetails && <td>{el.note}</td>}

            <td>
              <Box
                as="span"
                borderRadius="5px"
                fontSize="12px"
                whiteSpace="nowrap"
                paddingBlock={1}
                paddingInline={2}
                color={theme.light}
                background={
                  el.status === "pending"
                    ? "yellow.400"
                    : el.status === "preparing"
                    ? "orange"
                    : el.status === "shipped"
                    ? "#0094B7"
                    : el.status === "available" || el.status === "collected"
                    ? "green"
                    : "red"
                }
              >
                {t(`pages.orders.${el.status}`)}
              </Box>
            </td>
            <td>
              <Box>
                <Button
                  bg={theme.error}
                  fontSize="10px"
                  size="xs"
                  color={theme.light}
                  mb={4}
                  onClick={() =>
                    navigate(
                      `/orders/order_details/${el._id}?status=${el.status}`
                    )
                  }
                >
                  تفاصيل الطلب
                </Button>

                <Button
                  flex="1"
                  bg={theme.blue}
                  color={theme.light}
                  size="xs"
                  mb={2}
                  onClick={() => navigate(`/orders/${el._id}/notes`)}
                >
                  <Box position="relative">
                    <span>ملاحظات الطلب</span>
                    {el.replies.length > 0 && (
                      <Box
                        position="absolute"
                        top="-15px"
                        left="-1px"
                        w="15px"
                        h="15px"
                        bg={theme.error}
                        color={theme.light}
                        borderRadius="50%"
                        fontSize="6px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {el.replies?.length ?? 0}
                      </Box>
                    )}
                  </Box>
                </Button>
              </Box>

              {el.status !== "pending" && (
                <Button
                  type="button"
                  size="xs"
                  onClick={() => handleOrderStatus(el)}
                >
                  تتبع الطلب
                </Button>
              )}
            </td>
          </tr>
        ))}
        <tr>
          <td></td>
          <td>الإجمالي</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{orders?.data?.total}</td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </Table>
  );

  const admin = (
    <Table
      style={showDetails ? { display: "none" } : {}}
      id={showDetails ? "table-to-xls_orders" : ""}
    >
      <thead>
        <tr>
          <th>#</th>
          {["admin", "superadmin"].includes(auth.user?.rule?.name) && (
            <>
              <th>الربح</th>
              <th>الشحن</th>
            </>
          )}

          <th>مسوق</th>
          <th>{t("pages.orders.serial_number")}</th>
          <th>{t("pages.orders.client_name")}</th>
          <th>{t("pages.orders.client_phone1")}</th>
          {showDetails && <th>{t("pages.orders.client_phone2")}</th>}
          <th>{t("pages.orders.governorate")}</th>
          <th>{t("pages.orders.city")}</th>
          <th>{t("pages.orders.client_address")}</th>
          <th>{t("pages.orders.products")}</th>
          <th>{t("pages.orders.total")}</th>
          <th>{t("pages.orders.commission")}</th>
          <th>تاريخ الانشاء</th>
          <th>{t("pages.orders.last_update")}</th>
          {showDetails && <th>{t("pages.orders.note")}</th>}

          <th>شركة الشحن</th>
          <th>{t("pages.orders.status")}</th>
          {showDetails && <th>سبب الإلغاء</th>}
          {showDetails && <th>ملاحظة الإلغاء</th>}
          {showDetails && <th>سبب الإرجاع</th>}
          {showDetails && <th>ملاحظة الإرجاع</th>}
          {!showDetails && <th className="removable">{t("general.action")}</th>}
          {!showDetails && (
            <th className="removable">
              <Button type="button" onClick={handleSelectAllOrders}>
                تحديد الكل
              </Button>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr
            key={el._id}
            style={
              Object.hasOwn(el, "assigned_to") && el?.assigned_to?.length == 0
                ? {
                    backgroundColor: "#f1f1f1",
                  }
                : {}
            }
          >
            <td>
              <Flex
                alignItems="center"
                direction={"column"}
                justifyContent="center"
              >
                {Object.hasOwn(el, "assigned_to") &&
                  el?.assigned_to?.length == 0 && (
                    <BiSolidFlag style={{ color: "red", fontSize: "20px" }} />
                  )}
                {index + 1}
              </Flex>
            </td>
            {["admin", "superadmin"].includes(auth.user?.rule?.name) && (
              <>
                <td>{systemRevenue(el.items)}</td>
                <td>{el?.differenceShipping?.toFixed(2)}</td>
              </>
            )}
            <td>{el.marketer?.name || "-"}</td>
            <td>
              <CopyToClipboard
                text={el.serial_number}
                onCopy={() => {
                  toast({
                    position: "top",
                    description: "تم نسخ كود الطلب",
                  });
                }}
              >
                <Box color="#5fd2ff" cursor={"pointer"}>
                  {el.serial_number}
                </Box>
              </CopyToClipboard>
              <Divider my={2} border={"1px solid #ccc"} />
              {!checkPermissions("confirm_orders") &&
                Object.hasOwn(el, "assigned_to") &&
                el?.assigned_to?.length > 0 &&
                el?.assigned_to.map((item) => (
                  <CopyToClipboard
                    text={item?.code}
                    onCopy={() => {
                      toast({
                        position: "top",
                        description: "تم نسخ كود الموظف",
                      });
                    }}
                  >
                    <Box color="#d00" cursor={"pointer"}>
                      {item?.name}
                    </Box>
                  </CopyToClipboard>
                ))}
            </td>
            <td>{el.client_name}</td>
            <td>
              <CopyToClipboard
                text={el.client_phone1}
                onCopy={() => {
                  toast({
                    position: "top",
                    description: "تم نسخ رقم الهاتف ",
                  });
                }}
              >
                <Box color="#5fd2ff" cursor={"pointer"}>
                  {el.client_phone1}
                </Box>
              </CopyToClipboard>
            </td>
            {showDetails && <td>{el.client_phone2 || "-"}</td>}
            <td>
              {
                governorates.find(
                  (gov) => +gov.id === el.shipping_governorate?.governorate
                )?.governorate_name_ar
              }
            </td>
            <td>{cities.find((city) => +city.id === el.city)?.city_name_ar}</td>
            <td>
              {showDetails
                ? el.client_address
                : el.client_address.split(" ").slice(0, 3).join(" ") + "..."}
            </td>
            <td>
              {el.items?.length &&
                el.items.map(
                  (item, idx) =>
                    `(${item.qty}) قطعة ${item.product?.name} - ${
                      item.product.properties.find(
                        (it) => it._id === item.property
                      )?.key
                    } ${el.items?.length === idx + 1 ? "" : "--"} `
                )}
            </td>
            <td>{el.total}</td>
            <td>{el.commission}</td>
            <td>{new Date(el.created_at).toLocaleDateString()}</td>
            <td>{new Date(el.updated_at).toLocaleDateString()}</td>
            {showDetails && <td>{el.note}</td>}

            <td>{el.shipping_company?.name ?? "-"}</td>
            <td>
              <Box
                as="span"
                borderRadius="5px"
                fontSize="12px"
                whiteSpace="nowrap"
                paddingBlock={1}
                paddingInline={2}
                color={theme.light}
                background={
                  el.status === "pending"
                    ? "yellow.400"
                    : el.status === "preparing"
                    ? "orange"
                    : el.status === "shipped"
                    ? "#0094B7"
                    : el.status === "available" || el.status === "collected"
                    ? "green"
                    : "red"
                }
              >
                {t(`pages.orders.${el.status}`)}
              </Box>
              {el.status === "holding" && el.holding_to && (
                <Box color={theme.dark} bg={theme.light}>
                  {el.status === "holding" && el.holding_to
                    ? new Date(el.holding_to).toLocaleString()
                    : ""}
                </Box>
              )}
            </td>
            {showDetails && <td>{t(el?.canceling_reason) || ""}</td>}
            {showDetails && <td>{el?.canceling_reason_note}</td>}
            {showDetails && <td>{t(el?.skipping_reason) || ""}</td>}
            {showDetails && <td>{el?.skipping_reason_note}</td>}

            {!showDetails && (
              <td className="removable">
                <Box>
                  <Button
                    bg={theme.error}
                    fontSize="10px"
                    size="xs"
                    color={theme.light}
                    mb={4}
                    onClick={() =>
                      navigate(
                        `/orders/order_details/${el._id}?status=${el.status}&query=${query}`
                      )
                    }
                  >
                    تفاصيل الطلب
                  </Button>

                  <Button
                    flex="1"
                    bg={theme.blue}
                    color={theme.light}
                    size="xs"
                    mb={2}
                    onClick={() => navigate(`/orders/${el._id}/notes`)}
                  >
                    <Box position="relative">
                      <span>ملاحظات الطلب</span>
                      {el.replies.length > 0 && (
                        <Box
                          position="absolute"
                          top="-15px"
                          left="-1px"
                          w="15px"
                          h="15px"
                          bg={theme.error}
                          color={theme.light}
                          borderRadius="50%"
                          fontSize="6px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          {el.replies?.length ?? 0}
                        </Box>
                      )}
                    </Box>
                  </Button>

                  {auth.user.role === "admin" && el.status === "pending" && (
                    <Button
                      flex="1"
                      bg="transparent"
                      color="green"
                      size="xs"
                      mb={2}
                      onClick={() => handleUpdate(el)}
                    >
                      <FiIcons.FiEdit size={20} />
                    </Button>
                  )}
                </Box>

                {el.status !== "pending" && (
                  <Button
                    type="button"
                    size="xs"
                    onClick={() => handleOrderStatus(el)}
                  >
                    تتبع الطلب
                  </Button>
                )}
              </td>
            )}
            {!showDetails && (
              <td className="removable">
                <input
                  type="checkbox"
                  style={{ width: "25px", height: "25px" }}
                  className="order-check"
                  onChange={(e) => handleSelectOrder(el, e)}
                />
              </td>
            )}
          </tr>
        ))}
        <tr>
          <td>الإجمالي</td>
          {["admin", "superadmin"].includes(auth.user?.rule?.name) && (
            <>
              <th>{systemRevenueTotal}</th>
              <th>
                {data?.reduce(
                  (acc, el) => acc + (el.differenceShipping ?? 0),
                  0
                )}
              </th>
            </>
          )}
          <td></td>
          <td>
            <CopyToClipboard
              text={data.map((el) => el.serial_number).join(" ")}
              onCopy={() => {
                toast({
                  position: "top",
                  description: "تم نسخ  جميع الأكواد ",
                });
              }}
            >
              <Box color="#5fd2ff" cursor={"pointer"}>
                نسخ الأكواد
              </Box>
            </CopyToClipboard>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{orders?.data?.total}</td>
          <td>{orders?.data?.totalCommissions}</td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </Table>
  );
  //Orders Table for merchent
  const merchant = (
    <Table
      style={showDetails ? { display: "none" } : {}}
      id={showDetails ? "table-to-xls_orders" : ""}
    >
      <thead>
        <tr>
          <th>#</th>
          <th>{t("pages.orders.serial_number")}</th>
          <th>{t("pages.orders.products")}</th>
          <th>{t("pages.orders.purchase_price")}</th>
          <th>تاريخ الانشاء</th>
          <th>{t("pages.orders.last_update")}</th>
          <th>{t("pages.orders.governorate")}</th>

          <th>{t("pages.orders.status")}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr key={el._id}>
            <td>{index + 1}</td>
            <td>
              <CopyToClipboard
                text={el.serial_number}
                onCopy={() => {
                  toast({
                    position: "top",
                    description: "تم نسخ كود الطلب",
                  });
                }}
              >
                <Box color="#5fd2ff" cursor={"pointer"}>
                  {el.serial_number}
                </Box>
              </CopyToClipboard>
            </td>
            <td>
              {el.items?.length &&
                el.items.map(
                  (item, idx) =>
                    item.merchant === auth.user._id &&
                    `(${item.qty}) قطعة ${item.product?.name} - ${
                      item.product.properties.find(
                        (it) => it._id === item.property
                      )?.key
                    } ${el.items?.length === idx + 1 ? "" : "--"} `
                )}
            </td>

            <td>
              {/* {(
    el.items?.filter(item => item.merchant === auth.user._id)
      .map(item => item.total_purchase_price) || []
  ).map((price, idx) => (
    <div key={idx}>{price}</div>
  ))} */}
              {(
                el.items
                  ?.filter((item) => item.merchant === auth.user._id)
                  .map((item) => item.total_purchase_price) || []
              ).reduce((accumulator, price) => accumulator + price, 0)}
            </td>

            <td>{new Date(el.created_at).toLocaleDateString()}</td>
            <td>{new Date(el.updated_at).toLocaleDateString()}</td>
            <td>
              {
                governorates.find(
                  (gov) => +gov.id === el.shipping_governorate?.governorate
                )?.governorate_name_ar
              }
            </td>

            <td>
              <Box
                as="span"
                borderRadius="5px"
                fontSize="12px"
                whiteSpace="nowrap"
                paddingBlock={1}
                paddingInline={2}
                color={theme.light}
                background={
                  el.status === "pending"
                    ? "yellow.400"
                    : el.status === "preparing"
                    ? "orange"
                    : el.status === "shipped"
                    ? "#0094B7"
                    : el.status === "available" || el.status === "collected"
                    ? "green"
                    : "red"
                }
              >
                {t(`pages.orders.${el.status}`)}
              </Box>
              {el.status === "holding" && el.holding_to && (
                <Box color={theme.dark} bg={theme.light}>
                  {el.status === "holding" && el.holding_to
                    ? new Date(el.holding_to).toLocaleString()
                    : ""}
                </Box>
              )}
            </td>
          </tr>
        ))}
        <tr>
          <td></td>
          <td>الإجمالي</td>
          <td></td>
          <td>{orders?.data?.totalPurchases}</td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </Table>
  );

  const authQ = {
    marketer,
    moderator,
    admin,
    merchant,
  };

  return (
    <div style={{ transform: "rotateX(180deg)" }}>{authQ[auth.user.role]}</div>
  );
};

export default OrdersTable;
