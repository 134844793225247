import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
};

export const getDepartments = createAsyncThunk(
  "rules/getDepartments",
  async (args, thunkApi) => {
    try {
      const {
        page = 1,
        size = 10,
        query = "",
        filter = JSON.stringify({}),
      } = args;
      const response = await axios.get(
        `/api/get_departments?page=${page}&size=${size}&query=${query}&filter=${filter}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const createDepartment = createAsyncThunk(
  "rules/createDepartment",
  async (args, thunkApi) => {
    try {
      const response = await axios.post("/api/create_department", args, {
        headers: {
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const updateDepartment = createAsyncThunk(
  "rules/updateDepartment",
  async (args, thunkApi) => {
    try {
      const response = await axios.put(
        `/api/update_department/${args._id}`,
        args,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const deleteDepartment = createAsyncThunk(
  "rules/deleteDepartment",
  async (args, thunkApi) => {
    try {
      const response = await axios.delete(
        `/api/delete_department/${args._id}`,
        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

const departmentsSlice = createSlice({
  name: "departments",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get rules
      .addCase(getDepartments.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getDepartments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getDepartments.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors || [];
      })

      // Create rule
      .addCase(createDepartment.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.itemsCount = (state.data.itemsCount || 0) + 1;
        if (state.data.data?.length < 10) {
          state.data.data = [...(state.data.data || []), action.payload];
        }
        state.errors = [];
      })
      .addCase(createDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      })

      // Update rule
      .addCase(updateDepartment.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.data.data?.findIndex(
          (el) => el._id === action.payload._id
        );
        if (index !== -1) {
          state.data.data[index] = action.payload;
        }
        state.errors = [];
      })
      .addCase(updateDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      })

      // Delete rule
      .addCase(deleteDepartment.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.data =
          state.data.data?.filter((el) => el._id !== action.payload._id) || [];
        state.errors = [];
      })
      .addCase(deleteDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      });
  },
});

export default departmentsSlice.reducer;
