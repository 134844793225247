import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Flex,
  Button,
  Text,
  SimpleGrid,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";

import InputSearch from "../../shared/inputSearch/InputSearch";
import Pagination from "../../shared/pagination/Pagination";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import RulesTable from "./RulesTable";
import CreateModal from "./actions/CreateModal";
import UpdateModal from "./actions/UpdateModal";
import DeleteModal from "./actions/DeleteModal";
import Filter from "./actions/Filter";

import { getRules } from "../../../store/rules/rulesSlice";

import { RulesWrapper } from "./RulesStyle";
import theme from "../../global/theme";
import Departments from "./Departments";

const Rules = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rules = useSelector((state) => state.rules);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState(JSON.stringify({}));

  const [showCreateRule, setShowCreateRule] = useState(false);
  const [showUpdateRule, setShowUpdateRule] = useState(false);
  const [showDeleteRule, setShowDeleteRule] = useState(false);

  useEffect(() => {
    dispatch(getRules({ page, query, filter }));
  }, [dispatch, page, query, filter]);

  return (
    <RulesWrapper>
      <Breadcrumbs
        currentPage={t("pages.rules.rules")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {rules.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {rules.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      <SimpleGrid columns={{ sm: 1, md: 2 }} justifyContent="space-between">
        <Flex marginBottom={8}>
          <Button
            bg="red.600"
            textTransform="uppercase"
            fontWeight="bold"
            fontSize={18}
            marginInlineEnd={4}
            color="white"
            _hover={{ bg: "red.600" }}
            onClick={() => setShowCreateRule(true)}
          >
            {t("general.create")}
          </Button>
        </Flex>

        <Flex marginBottom={8} justifyContent="flex-end">
          <InputSearch
            isLoading={rules.isLoading}
            handleSearch={(query) => setQuery(query)}
          />

          <Filter
            handleFilter={(values) => setFilter(values)}
            handleClearFilter={() => setFilter(JSON.stringify({}))}
            disablesBtns={rules.isLoading}
          />
        </Flex>
      </SimpleGrid>

      {rules.data?.itemsCount > 0 ? (
        <RulesTable
          data={rules.data.data}
          page={page}
          handleUpdate={(el) => setShowUpdateRule(el)}
          handleDelete={(el) => setShowDeleteRule(el)}
        />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={4}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            {t("pages.rules.no_rules")}
          </Text>
        </Flex>
      )}

      <Flex justifyContent="flex-end">
        <Pagination
          page={page}
          itemsCount={rules.data?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

      {showCreateRule && (
        <CreateModal onClose={() => setShowCreateRule(false)} />
      )}
      {showUpdateRule && (
        <UpdateModal
          data={showUpdateRule}
          onClose={() => setShowUpdateRule(false)}
        />
      )}
      {showDeleteRule && (
        <DeleteModal
          data={showDeleteRule}
          onClose={() => setShowDeleteRule(false)}
        />
      )}
      <Departments />
    </RulesWrapper>
  );
};

export default Rules;
