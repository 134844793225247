import { Box, Stack, Button, Flex, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updateNotification } from "../../../store/notifications/notificationsSlice";

import theme from "../../global/theme";

const NotificationsList = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <>
      <Flex
        bg={theme.dark}
        spacing={6}
        p={4}
        borderRadius={4}
        flexDirection={"column"}
        gap={4}
      >
        {data.map((el) =>
          el.type === "order_note" ? (
            <Button
              key={el._id}
              opacity={el.is_read ? 0.8 : 1}
              type="button"
              display="flex"
              whiteSpace="normal"
              textOverflow="ellipsis"
              flexWrap={"wrap"}
              onClick={() => {
                if (!el.is_read) {
                  dispatch(
                    updateNotification({
                      _id: el._id,
                      is_read: true,
                    })
                  )
                    .unwrap()
                    .then((_) => {
                      navigate(`/orders/${el.order?._id}/notes`, {
                        state: el.order,
                      });
                    });
                } else {
                  navigate(`/orders/${el.order?._id}/notes`, {
                    state: el.order,
                  });
                }
              }}
            >
              <Box
                color={!el.is_read && theme.error}
                borderRadius={4}
                p={{ base: 0, lg: 3 }}
                fontSize={{ base: "10px", lg: "12px" }}
                flex={1}
              >
                <Text fontSize={{ base: 10, lg: 12 }}>
                  لديك رساله في ملاحظات الطلب رقم
                  <b>{el.order?.serial_number}</b>
                </Text>
              </Box>
            </Button>
          ) : el.type === "order_status" ? (
            <Button
              key={el._id}
              opacity={el.is_read ? 0.8 : 1}
              type="button"
              display="flex"
              whiteSpace="normal"
              overflow="hidden"
              textOverflow="ellipsis"
              flexWrap={"wrap"}
              onClick={() => {
                if (!el.is_read) {
                  dispatch(
                    updateNotification({
                      _id: el._id,
                      is_read: true,
                    })
                  )
                    .unwrap()
                    .then((_) => {
                      navigate(`/orders/order_details/${el.order?._id}`);
                    });
                } else {
                  navigate(`/orders/order_details/${el.order?._id}`);
                }
              }}
            >
              <Box
                color={!el.is_read && theme.error}
                borderRadius={4}
                p={3}
                flex={1}
              >
                {/* <Box display="inline-block" me={3}>تم تغير حالة طلبك رقم</Box> */}
                {/* <b>{el.order?.serial_number}</b> */}
                <Box
                  color={!el.is_read && theme.error}
                  borderRadius={4}
                  p={{ base: 0, lg: 3 }}
                  fontSize={{ base: "10px", lg: "12px" }}
                  flex={1}
                >
                  <Text fontSize={{ base: 10, lg: 12 }}>{el.content}</Text>
                </Box>
              </Box>
            </Button>
          ) : el.type === "request" ? (
            <Button
              key={el._id}
              opacity={el.is_read ? 0.8 : 1}
              type="button"
              display="flex"
              whiteSpace="normal"
              overflow="hidden"
              textOverflow="ellipsis"
              flexWrap={"wrap"}
              onClick={() => {
                if (!el.is_read) {
                  dispatch(
                    updateNotification({
                      _id: el._id,
                      is_read: true,
                    })
                  )
                    .unwrap()
                    .then((_) => {
                      navigate("/requests");
                    });
                } else {
                  navigate("/requests");
                }
              }}
            >
              <Box
                color={!el.is_read && theme.error}
                borderRadius={4}
                p={{ base: 0, lg: 3 }}
                fontSize={{ base: "10px", lg: "12px" }}
                flex={1}
              >
                <Text fontSize={{ base: 10, lg: 12 }}>{el.content}</Text>
              </Box>
            </Button>
          ) : el.type === "ticket" ? (
            <Button
              key={el._id}
              opacity={el.is_read ? 0.8 : 1}
              type="button"
              display="flex"
              p={0}
              whiteSpace="normal"
              textOverflow="ellipsis"
              flexWrap={"wrap"}
              onClick={() => {
                if (!el.is_read) {
                  dispatch(
                    updateNotification({
                      _id: el._id,
                      is_read: true,
                    })
                  )
                    .unwrap()
                    .then((_) => {
                      navigate(`/available_products/${el.product?._id}`);
                    });
                } else {
                  navigate(`/available_products/${el.product?._id}`);
                }
              }}
            >
              <Box
                color={!el.is_read && theme.error}
                borderRadius={4}
                p={{ base: 0, lg: 3 }}
                fontSize={{ base: "10px", lg: "12px" }}
                flex={1}
              >
                <Text fontSize={{ base: 10, lg: 12 }}>{el.content}</Text>
              </Box>
            </Button>
          ) : el.type === "assign_order" ? (
            <Button
              key={el._id}
              opacity={el.is_read ? 0.8 : 1}
              type="button"
              display="flex"
              p={0}
              whiteSpace="normal"
              textOverflow="ellipsis"
              flexWrap={"wrap"}
              onClick={() => {
                if (!el.is_read) {
                  dispatch(
                    updateNotification({
                      _id: el._id,
                      is_read: true,
                    })
                  )
                    .unwrap()
                    .then((_) => {
                      navigate(`/orders`);
                    });
                } else {
                  navigate(`/orders`);
                }
              }}
            >
              <Box
                color={!el.is_read && theme.error}
                borderRadius={4}
                p={{ base: 0, lg: 3 }}
                fontSize={{ base: "10px", lg: "12px" }}
                flex={1}
              >
                <Text fontSize={{ base: 10, lg: 12 }}>{el.content}</Text>
              </Box>
            </Button>
          ) : (
            <span>-</span>
          )
        )}
      </Flex>
    </>
  );
};

export default NotificationsList;
