import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FiBellOff, FiCheckCircle } from "react-icons/fi";
import {
  Flex,
  Text,
  Alert,
  AlertIcon,
  Box,
  Button,
  Spinner,
  useToast,
  ButtonGroup,
  Skeleton,
  Stack,
  Icon,
} from "@chakra-ui/react";

import Pagination from "../../shared/pagination/Pagination";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import NotificationsList from "./NotificationsList";

import {
  getNotifications,
  updateNotifications,
} from "../../../store/notifications/notificationsSlice";

import { NotificationsWrapper } from "./NotificationsStyle";
import theme from "../../global/theme";
import useAuth from "../../../hooks/useAuth";

const Notifications = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const { checkPermissions } = useAuth();
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);
  const auth = useSelector((state) => state.auth);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [type, setType] = useState("");
  const [isMarkingRead, setIsMarkingRead] = useState(false);

  // Skeleton Loader
  const NotificationsSkeleton = () => (
    <Stack spacing={4} p={4}>
      {[...Array(5)].map((_, i) => (
        <Skeleton
          key={i}
          height="80px"
          borderRadius="lg"
          startColor={theme.gary1}
          endColor={theme.gary1}
        />
      ))}
    </Stack>
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(
          getNotifications({
            page,
            query,
            filter: type ? JSON.stringify({ type }) : "{}",
          })
        ).unwrap();
      } catch (error) {
        toast({
          title: "حدث خطأ",
          description: "فشل في تحميل الإشعارات",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchData();
  }, [dispatch, page, query, type, toast]);

  const handleFilterChange = (newType) => {
    setType(newType);
    setPage(1);
  };

  const handleMarkAllAsRead = async () => {
    setIsMarkingRead(true);
    try {
      await dispatch(updateNotifications()).unwrap();
      toast({
        title: "تم التحديث بنجاح",
        icon: <FiCheckCircle />,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      dispatch(
        getNotifications({
          page,
          query,
          filter: type ? JSON.stringify({ type }) : "{}",
        })
      );
    } catch (error) {
      toast({
        title: "حدث خطأ",
        description: "فشل في تحديث الإشعارات",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsMarkingRead(false);
    }
  };

  return (
    <NotificationsWrapper>
      <Breadcrumbs
        currentPage="الإشعارات"
        pages={[{ name: "الرئيسية", path: "/" }]}
      />

      {/* Filter Section */}
      <Box
        bg="white"
        p={4}
        borderRadius="lg"
        boxShadow="sm"
        mb={6}
        border="1px solid"
        borderColor="gray.100"
      >
        <Text
          fontSize="sm"
          fontWeight="600"
          color="gray.600"
          mb={3}
          textTransform="uppercase"
        >
          الفلترة حسب النوع
        </Text>

        <ButtonGroup
          spacing={2}
          flexWrap="wrap"
          gap={2}
          variant="outline"
          size="sm"
        >
          <Button
            leftIcon={<Icon as={FiBellOff} />}
            onClick={() => handleFilterChange("")}
            bg={!type ? theme.primary : "white"}
            color={!type ? "white" : "gray.600"}
            borderColor={!type ? theme.primary : "gray.200"}
            _hover={{
              bg: !type ? theme.primaryDark : "gray.50",
            }}
          >
            جميع الاشعارات
          </Button>

          {[
            "order_note",
            "request",
            "order_status",
            "ticket",
            "assign_order",
          ].map((filterType) => (
            <FilterButton
              key={filterType}
              type={filterType}
              currentType={type}
              onFilterChange={handleFilterChange}
              checkPermissions={checkPermissions}
              auth={auth}
            />
          ))}
        </ButtonGroup>
      </Box>

      {/* Actions Section */}
      <Flex
        justify="space-between"
        align="center"
        bg="white"
        p={4}
        borderRadius="lg"
        boxShadow="sm"
        mb={6}
      >
        <Text fontSize="lg" fontWeight="600" color="gray.700">
          الإشعارات الحديثة
        </Text>
        <Button
          size="sm"
          colorScheme="blue"
          onClick={handleMarkAllAsRead}
          isLoading={isMarkingRead}
          leftIcon={<Icon as={FiCheckCircle} />}
        >
          تمييز الكل كمقروء
        </Button>
      </Flex>

      {/* Content Section */}
      {notifications.isLoading ? (
        <NotificationsSkeleton />
      ) : notifications.data?.itemsCount > 0 ? (
        <>
          <NotificationsList data={notifications.data.data} />
          <Flex justify="flex-end" mt={6}>
            <Pagination
              page={page}
              itemsCount={notifications.data.itemsCount}
              onChange={setPage}
              siblingCount={1}
              showFirstLast
            />
          </Flex>
        </>
      ) : (
        <EmptyState />
      )}
    </NotificationsWrapper>
  );
};

// Sub-components
const FilterButton = ({
  type,
  currentType,
  onFilterChange,
  checkPermissions,
  auth,
}) => {
  if (
    (type === "request" && !checkPermissions("manage_requests")) ||
    (type === "ticket" &&
      !(auth.user.role === "admin" && checkPermissions("low_products"))) ||
    (type === "assign_order" &&
      !(auth.user.role === "admin" && checkPermissions("confirm_orders")))
  ) {
    return null;
  }

  const buttonText = {
    order_note: "ملاحظات الطلب",
    request: "طلبات السحب",
    order_status: "تغيير حالة الطلب",
    ticket: "تغيير المخزون",
    assign_order: "طلباتي",
  }[type];

  return (
    <Button
      onClick={() => onFilterChange(type)}
      bg={currentType === type ? theme.primary : "white"}
      color={currentType === type ? "white" : "gray.600"}
      borderColor={currentType === type ? theme.primary : "gray.200"}
      _hover={{
        bg: currentType === type ? theme.primaryDark : "gray.50",
      }}
    >
      {buttonText}
    </Button>
  );
};

const EmptyState = () => (
  <Flex
    direction="column"
    align="center"
    justify="center"
    bg="white"
    p={8}
    borderRadius="lg"
    boxShadow="sm"
    height="300px"
  >
    <Icon as={FiBellOff} w={12} h={12} color="gray.300" mb={4} />
    <Text fontSize="xl" fontWeight="600" color="gray.500" mb={2}>
      لا توجد إشعارات
    </Text>
    <Text color="gray.500" textAlign="center">
      لا توجد إشعارات جديدة لعرضها حاليا
    </Text>
  </Flex>
);

export default React.memo(Notifications);
