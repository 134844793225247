import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, Button, useToast, Flex } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProducts,
  updateProductsOrder,
} from "../../../../store/products/productsOrderSlice";

const ProductsOrder = () => {
  const productsOrder = useSelector((state) => state.productsOrder);
  const [products, setProducts] = useState([]);
  const toast = useToast({
    duration: 5000,
    status: "success",
    position: "top",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProducts());
  }, []);

  useEffect(() => {
    setProducts(productsOrder?.data);
  }, [productsOrder?.data]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(products);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setProducts(items);
  };

  const handleSaveOrder = () => {
    const updatedProducts = products.map((product, index) => ({
      ...product,
      order: index,
    }));

    dispatch(updateProductsOrder(updatedProducts));
    toast({ description: "تم حفظ الترتيب بنجاح" });
  };

  const handleRandomizeOrder = () => {
    const shuffledProducts = [...products];

    for (let i = shuffledProducts.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledProducts[i], shuffledProducts[j]] = [
        shuffledProducts[j],
        shuffledProducts[i],
      ];
    }

    setProducts(shuffledProducts);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Flex m="4" gap="4">
        <Button colorScheme="blue" onClick={handleSaveOrder}>
          حفظ الترتيب
        </Button>
        <Button colorScheme="teal" onClick={handleRandomizeOrder}>
          ترتيب عشوائي
        </Button>
      </Flex>
      <Droppable droppableId="products">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {products.map((product, index) => (
              <Draggable
                key={product._id}
                draggableId={product._id}
                index={index}
              >
                {(provided) => (
                  <Box
                    bg="white"
                    p="2"
                    borderRadius="md"
                    shadow="md"
                    mb="2"
                    cursor="grab"
                    _hover={{ bg: "gray.200" }}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {product.name}
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ProductsOrder;
